import React from 'react';
import { Footer } from '../components/Footer';
import { Container } from 'react-bootstrap';
import { Header } from '../components/Header';

function Error404() {
    return (
        <>
            <Header />
            <Container style={{ height: '40vh', display: 'grid', alignContent: 'center' }} className='text-center' fluid="md">
                <h1>404</h1>
                <h1>Ooops!! Pagina No Encontrada</h1>
                <h3>Lo sentimos, La URL solicitada no se encontró en este servidor.</h3>
            </Container>
            <Footer />
        </>
    );
}

export { Error404 };