import React, { useEffect, useState } from 'react';
import { Header } from '../components/Header';
import { Footer } from '../components/Footer';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Cookies from 'universal-cookie';
import axios from 'axios';
import moment from 'moment';

function ResponsePago() {
    const cookies = new Cookies();
    const requestId = cookies.get('requestId');
    const accessToken = cookies.get('accesToken');
    const [pago, setPago] = useState({
        "status": {
            "status": '',
            "message": '',
        },
        "reference": '',
        "descripcion": '',
        "total": '',
        "usuario": '',
        "moneda": '',
    });

    const estadoSesion = {
        '': '',
        'PENDING': 'Pendiente',
        'APPROVED': 'Aprobada',
        'REJECTED': 'Rechazada'
    }

    const formatoPesosColombianos = new Intl.NumberFormat('es-CO', {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 0
    });

    const consultarRespuesta = async () => {

        if (requestId) {
            const url = process.env.REACT_APP_SERVER + '/api/v1/pedidos/authPlacetoPay';
            const { data: autorizacion } = await axios.post(url, { requestId: requestId }, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });

            if (autorizacion.result) {
                let body = {
                    "auth": {
                        "login": autorizacion.data.login,
                        "tranKey": autorizacion.data.tranKey,
                        "nonce": autorizacion.data.nonce,
                        "seed": autorizacion.data.seed
                    }
                }

                const urlCheckout = process.env.REACT_APP_BASE_URL_GOU+'/api/session/' + requestId;
                const { data, status } = await axios.post(urlCheckout, body);

                if (status === 200) {
                    let fecha = moment(status.date).format('YYYY-MM-DD, h:mm:ss');
                    if (data.payment) {
                        if (data.payment.length > 0) {
                            fecha = moment(data.payment[0].status.date).format('YYYY-MM-DD, h:mm:ss');
                        }
                    }
                    let pagoAux = {
                        "status": {
                            "status": estadoSesion[data.status.status],
                            "message": data.status.message
                        },
                        "requestId": requestId,
                        "date": fecha,
                        "reference": data.request.payment.reference,
                        "descripcion": data.request.payment.description,
                        "total": data.request.payment.amount.total,
                        "usuario": data.request.buyer.email,
                        "moneda": data.request.payment.amount.currency,
                    }
                    setPago(pagoAux);

                    const urlTransaccion = process.env.REACT_APP_SERVER + '/api/v1/pedidos/transaccion/update';
                    await axios.post(urlTransaccion, pagoAux, {
                        headers: {
                            'Authorization': `Bearer ${accessToken}`
                        }
                    });
                }
            }
        }
    }
    useEffect(() => {
        consultarRespuesta();
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <Header />
            <Container>
                <Row>
                    <Col sm={12} style={{ textAlign: 'center' }}>
                        <h3>Resultado de la Transacción</h3>
                    </Col>
                </Row>
                <br></br>
                {
                    pago.status.status === 'Pendiente'
                        ?
                        <Row>
                            <Col sm={12} style={{ textAlign: 'center' }}>
                                <h6>En este momento el pago de su pedido se encuentra en un estado de <b>PENDIENTE</b> de no recibir confirmación por parte de su entidad financiera, por favor espere unos minutos y vuelva a
                                    consultar más tarde para verificar si su pago fue confirmado de forma exitosa. Si desea más información sobre el estado actual de su operación
                                    puede comunicarse a nuestras líneas de atención al cliente o al correo <b><a href='mailto:responsable.web@ferremaster.com.co'>responsable.web@ferremaster.com.co</a></b> y preguntar por el estado de la transacción.</h6>
                            </Col>
                        </Row>
                        :
                        ''
                }
                <br></br>
                <Row>
                    <Col md={{ span: 4, offset: 3 }}>
                        <b>Fecha y Hora:</b>
                    </Col>
                    <Col sm={5}>
                        {pago.date}
                    </Col>
                </Row>
                <Row>
                    <Col md={{ span: 4, offset: 3 }}>
                        <b>Estado de la transacción:</b>
                    </Col>
                    <Col sm={5}>
                        {pago.status.status}
                    </Col>
                </Row>
                <Row>
                    <Col md={{ span: 4, offset: 3 }}>
                        <b>Referencia de la transacción:</b>
                    </Col>
                    <Col sm={5}>
                        {pago.reference}
                    </Col>
                </Row>
                <Row>
                    <Col md={{ span: 4, offset: 3 }}>
                        <b>Usuario:</b>
                    </Col>
                    <Col sm={5}>
                        {pago.usuario}
                    </Col>
                </Row>
                <Row>
                    <Col md={{ span: 4, offset: 3 }}>
                        <b>Valor total:</b>
                    </Col>
                    <Col sm={5}>
                        {formatoPesosColombianos.format(pago.total)}
                    </Col>
                </Row>
                <Row>
                    <Col md={{ span: 4, offset: 3 }}>
                        <b>Moneda:</b>
                    </Col>
                    <Col sm={5}>
                        {pago.moneda}
                    </Col>
                </Row>
                <Row>
                    <Col md={{ span: 4, offset: 3 }}>
                        <b>Descripción:</b>
                    </Col>
                    <Col sm={5}>
                        <em>{pago.descripcion}</em>
                    </Col>
                </Row>
                <br></br>
                <br></br>
                <Row>
                    <Col sm={12} style={{ textAlign: 'center' }}>
                        <Link to="/estadoPedidos" className="btn btn-warning" >Consultar Mis Pedidos</Link>
                    </Col>
                </Row>
            </Container>
            <Footer />
        </>
    );
}

export { ResponsePago };