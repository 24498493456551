import React from 'react';
import { Footer } from '../components/Footer';
import { Container, Row, Col } from 'react-bootstrap';
import { Header } from '../components/Header';
import '../sources/styles/stylesTexto.css';

function ProteccionDatos() {

    const manual = process.env.REACT_APP_SERVER_IMAGE + 'documentos/FM-SIG-001 AUTORIZACION PARA EL TRATAMIENTO DE DATOS PERSONALES V.003 .pdf';
    const pagina = 'https://ferremaster.com/';

    return (
        <>
            <Container fluid="md">
                <Header />
                <br />
                <h1>MANUAL DE PROTECCIÓN Y USO DE DATOS PERSONALES</h1>
                <div className='dateTable'>
                    <h2>LEY 1581 DE 2012</h2>
                    <h2>DECRETO 1377 DE 2013</h2>
                    <h2>GUADALAJARA DE BUGA, VALLE DEL CAUCA</h2>
                    <h2>PREAMBULO</h2>
                </div>
                <p>Los datos personales conforman la información necesaria para que una persona pueda interactuar con otras o con una o más empresas y/o entidades para que sea plenamente individualizada del resto de la sociedad, haciendo posible la generación de flujos de información que contribuyen con el crecimiento económico y el mejoramiento de bienes y servicios.</p>
                <p>En este orden de ideas, se hace imprescindible una adecuada gestión, protección y tratamiento de las bases de datos, siendo esta parte integral de su vida privada y derecho a un buen nombre, como principio constitucional, sin menoscabar la sana administración de riesgos y conocimiento que deben tener todas las compañías con respecto a sus contrapartes.</p>
                <p>En tal sentido, la ley 1581 de 2012, tiene por objeto desarrollar el derecho constitucional que tienen todas las personas a conocer, actualizar y rectificar las informaciones que se hayan recogido sobre ellas en bases de datos o archivos, y los demás derechos, libertades y garantías constitucionales a que se refiere el artículo 15 de la Constitución Política; así como el derecho a la información consagrado en el artículo 20 de la misma.</p>
                <p>Como resultado, la empresa se acoge a lo que estipula el artículo 27 del Reglamento 1377, donde los responsables de los Tratamientos deberán garantizar la existencia de una estructura administrativa, proporcional a la estructura de la empresa, que vele por el cumplimiento de la ley 1581. Entendiendo este aspecto como crucial para que la adecuación e implantación de la privacidad y protección de datos sean efectivos.</p>
                <h3 className='negrita'>DATOS GENERALES DE SOCIEDAD FERRETERA DE COMERCIO S.A.S. COMO RESPONSABLE DEL TRATAMIENTO</h3>
                <br />
                <div className='dateTable'>
                    <Row>
                        <Col sm={4}><p className='negrita'>NIT:</p></Col>
                        <Col sm={6}><p  >800052377-6</p></Col>
                    </Row>
                    <Row>
                        <Col sm={4}><p className='negrita'>DOMICILIO:</p></Col>
                        <Col sm={6}><p  > Calle 7 No. 10-50 Buga Valle Del Cauca</p></Col>
                    </Row>
                    <Row>
                        <Col sm={4}><p className='negrita'>TELÉFONO:</p></Col>
                        <Col sm={6}><p  > 57 (2) 2363000</p></Col>
                    </Row>
                    <Row>
                        <Col sm={4}><p className='negrita'>PÁGINA WEB:</p></Col>
                        <Col sm={6}><p  ><a target={'new'} href={pagina}>www.ferremaster.com</a></p></Col>
                    </Row>
                </div>
                <br />
                <h3 className='negrita'>OBJETIVO</h3>
                <p>El presente Manual tiene por objeto proteger el derecho constitucional que tienen todas las personas a conocer, actualizar y rectificar las informaciones que se hayan recogido sobre ellas en las bases de datos o archivos de propiedad de la compañía, o cuyo tratamiento ha sido encargado a la misma, en desarrollo y cumplimiento de su objeto social, así como los demás derechos, libertades y garantías constitucionales a que se refieren los artículos 15 (derecho a la intimidad) y 20 (derecho a la información) de la Constitución Política de Colombia.</p>
                <h3 className='negrita'>ALCANCE</h3>
                <p>SOCIEDAD FERRETERA DE COMERCIO S.A.S. en el rol de responsable o encargada del tratamiento de los datos personales, está comprometida con el adecuado tratamiento de los datos de sus empleados, su junta directiva, sus accionistas, sus clientes, sus proveedores y los terceros. Por lo tanto, en el presente documento se articulan los   procesos y actividades que involucran el tratamiento de los datos personales, los cuales están alineados con las normas y directrices que lo regulan.</p>
                <h3 className='negrita'>GLOSARIO</h3>
                <p>
                    <label className='negrita'> 1.	Autorización:</label> Consentimiento previo, expreso e informado del Titular para llevar a cabo el Tratamiento de datos personales;<br />
                    <label className='negrita'> 2.	Base de Datos:</label> Conjunto organizado de datos personales que sea objeto de Tratamiento;<br />
                    <label className='negrita'> 3.	Dato personal:</label> Cualquier información vinculada o que pueda asociarse a una o varias personas naturales determinadas o determinables;<br />
                    <label className='negrita'> 4.	Encargado del Tratamiento:</label> Persona natural o jurídica, pública o privada, que por sí misma o en asocio con otros, realice el Tratamiento de datos personales por cuenta del responsable del Tratamiento;<br />
                    <label className='negrita'> 5.	e) Responsable del Tratamiento:</label> Persona natural o jurídica, pública o privada, que por sí misma o en asocio con otros, decida sobre la base de datos y/o el Tratamiento de los datos;<br />
                    <label className='negrita'> f) Titular:</label> Persona natural cuyos datos personales sean objeto de Tratamiento;<br />
                    <label className='negrita'> g) Tratamiento:</label> Cualquier operación o conjunto de operaciones sobre datos personales, tales como la recolección, almacenamiento, uso, circulación o supresión.<br />
                </p>
                <h3 className='negrita'>POLITICA GENERAL</h3>
                <p>SOCIEDAD FERRETERA DE COMERCIO S.A.S. en su calidad de entidad vigilada, propende por la comprensión, práctica y ejecución de todos los mecanismos e instrumentos establecidos en la normativa para la protección de datos de todos sus agentes económicos.</p>
                <h3 className='negrita'>PRINCIPIOS RECTORES</h3>
                <p>
                    <div className='dateInforme'>
                        <label className='negrita'>•	Principio de legalidad en materia de Tratamiento de datos:</label> El Tratamiento a que se refiere la ley es una actividad reglada que debe sujetarse a lo establecido en ella y en las demás disposiciones que la desarrollen;<br />
                        <label className='negrita'>•	Principio de finalidad:</label> El Tratamiento debe obedecer a una finalidad legítima de acuerdo con la Constitución y la Ley, la cual debe ser informada al Titular;<br />
                        <label className='negrita'>•	Principio de libertad:</label> El Tratamiento sólo puede ejercerse con el consentimiento, previo, expreso e informado del Titular. Los datos personales no podrán ser obtenidos o divulgados sin previa autorización, o en ausencia de mandato legal o judicial que releve el consentimiento;<br />
                        <label className='negrita'>•	Principio de veracidad o calidad:</label> La información sujeta a Tratamiento debe ser veraz, completa, exacta, actualizada, comprobable y comprensible. Se prohíbe el Tratamiento de datos parciales, incompletos, fraccionados o que induzcan a error;<br />
                        <label className='negrita'>•	Principio de transparencia:</label> En el Tratamiento debe garantizarse el derecho del Titular a obtener del responsable del Tratamiento o del Encargado del Tratamiento, en cualquier momento y sin restricciones, información acerca de la existencia de datos que le conciernan;<br />
                        <label className='negrita'>•	Principio de acceso y circulación restringida:</label> El Tratamiento se sujeta a los límites que se derivan de la naturaleza de los datos personales, de las disposiciones de la ley y la Constitución. En este sentido, el Tratamiento sólo podrá hacerse por personas autorizadas por el Titular y/o por las personas previstas en la ley; Los datos personales, salvo la información pública, no podrán estar disponibles en Internet u otros medios de divulgación o comunicación masiva, salvo que el acceso sea técnicamente controlable para brindar un conocimiento restringido sólo a los Titulares o terceros autorizados conforme a la ley;<br />
                        <label className='negrita'>•	Principio de seguridad:</label> La información sujeta a Tratamiento por el Responsable del Tratamiento o Encargado del Tratamiento a que se refiere la ley, se deberá manejar con las medidas técnicas, humanas y administrativas que sean necesarias para otorgar seguridad a los registros evitando su adulteración, pérdida, consulta, uso o acceso no autorizado o fraudulento;<br />
                        <label className='negrita'>•	Principio de confidencialidad: </label>Todas las personas que intervengan en el Tratamiento de datos personales que no tengan la naturaleza de públicos están obligadas a garantizar la reserva de la información, inclusive después de finalizada su relación con alguna de las labores que comprende el Tratamiento, pudiendo sólo realizar suministro o comunicación de datos personales cuando ello corresponda al desarrollo de las actividades autorizadas en la ley y en los términos de la misma.<br />
                    </div>
                </p>
                <h3 className='negrita'>DERECHOS Y CONDICIONES DE LEGALIDAD PARA EL TRATAMIENTO DE DATOS</h3>
                <p>
                    <div className='dateInforme'>
                        <label className='negrita'>Datos sensibles.</label> Para los propósitos de la ley, se entiende por datos sensibles aquellos que afectan la intimidad del Titular o cuyo uso indebido puede generar su discriminación, tales como aquellos que revelen el origen racial o étnico, la orientación política, las convicciones religiosas o filosóficas, la pertenencia a sindicatos, organizaciones sociales, de derechos humanos o que promueva intereses de cualquier partido político o que garanticen los derechos y garantías de partidos políticos de oposición así como los datos relativos a la salud, a la vida sexual y los datos biométricos.<br /><br />
                        Tratamiento de datos sensibles. Se prohíbe el Tratamiento de datos sensibles, excepto cuando:<br />
                        •	El Titular haya dado su autorización explícita a dicho Tratamiento, salvo en los casos que por ley no sea requerido el otorgamiento de dicha autorización;<br />
                        •	El Tratamiento sea necesario para salvaguardar el interés vital del Titular y este se encuentre física o jurídicamente incapacitado. En estos eventos, los representantes legales deberán otorgar su autorización.<br />
                        •	El Tratamiento sea efectuado en el curso de las actividades legítimas y con las debidas garantías por parte de una fundación, ONG, asociación o cualquier otro organismo sin ánimo de lucro, cuya finalidad sea política, filosófica, religiosa o sindical, siempre que se refieran exclusivamente a sus miembros o a las personas que mantengan contactos regulares por razón de su finalidad. En estos eventos, los datos no se podrán suministrar a terceros sin la autorización del Titular.<br />
                        •	El Tratamiento se refiera a datos que sean necesarios para el reconocimiento, ejercicio o defensa de un derecho en un proceso judicial.<br />
                        •	El Tratamiento tenga una finalidad histórica, estadística o científica. En este evento deberán adoptarse las medidas conducentes a la supresión de identidad de los Titulares.<br />
                        <br />
                        <label className='negrita'> Derechos de los niños, niñas y adolescentes.</label><br /><br />

                        En el Tratamiento se asegurará el respeto a los derechos prevalentes de los niños, niñas y adolescentes. Queda proscrito el Tratamiento de datos personales de niños, niñas y adolescentes, salvo aquellos datos que sean de naturaleza pública.<br /><br />
                        Es tarea del Estado y de las entidades educativas de todo tipo proveer información y capacitar a los representantes legales y tutores sobre los eventuales riesgos a los que se enfrentan los niños, niñas y adolescentes respecto del Tratamiento indebido de sus datos personales, y proveer de conocimiento acerca del uso responsable y seguro por parte de niños, niñas y adolescentes de sus datos personales, su derecho a la privacidad y protección de su información personal y la de los demás.<br /><br />
                        Derechos de los Titulares. El Titular de los datos personales tendrá los siguientes derechos:<br /><br />
                        a) Conocer, actualizar y rectificar sus datos personales frente a los responsables del tratamiento o encargados del tratamiento. Este derecho se podrá ejercer, entre otros frente a datos parciales, inexactos, incompletos, fraccionados, que induzcan a error, o aquellos cuyo Tratamiento esté expresamente prohibido o no haya sido autorizado;<br /><br />
                        b) Solicitar prueba de la autorización otorgada al responsable del tratamiento salvo cuando expresamente se exceptúe como requisito para el Tratamiento, de conformidad con lo previsto en el artículo 10 de la ley.<br /><br />
                        c) Ser informado por el responsable del tratamiento o el encargado del tratamiento, previa solicitud, respecto del uso que les ha dado a sus datos personales.<br /><br />
                        d) Presentar ante la Superintendencia de Industria y Comercio quejas por infracciones a lo dispuesto en la ley y las demás normas que la modifiquen, adicionen o complementen.<br /><br />
                        e) Revocar la autorización y/o solicitar la supresión del dato cuando en el tratamiento no se respeten los principios, derechos y garantías constitucionales y legales. La revocatoria y/o supresión procederá cuando la Superintendencia de Industria y Comercio haya determinado que en el Tratamiento el responsable o encargado han incurrido en conductas contrarias a esta ley y a la Constitución;<br /><br />
                        f) Acceder en forma gratuita a sus datos personales que hayan sido objeto de Tratamiento.<br /><br />

                        <br />
                        <label className='negrita'>Autorización del Titular.</label><br /><br />
                        Sin perjuicio de las excepciones previstas en la ley, en el tratamiento se requiere la autorización previa e informada del titular, la cual deberá ser obtenida por cualquier medio que pueda ser objeto de consulta posterior.<br /><br />
                        Casos en que no es necesaria la autorización. La autorización del titular no será necesaria cuando se trate de:<br /><br />
                        •	Información requerida por una entidad pública o administrativa en ejercicio de sus funciones legales o por orden judicial;<br />
                        •	Datos de naturaleza pública;<br />
                        •	Casos de urgencia médica o sanitaria;<br />
                        •	Tratamiento de información autorizado por la ley para fines históricos, estadísticos o científicos;<br />
                        •	Datos relacionados con el Registro Civil de las Personas.<br /><br />
                        Quien acceda a los datos personales sin que medie autorización previa deberá, en todo caso, cumplir con las disposiciones contenidas en la ley.<br /><br />
                        <label className='negrita'>Suministro de la información.</label> La información solicitada podrá ser suministrada por cualquier medio, incluyendo los electrónicos, según lo requiera el titular. La información deberá ser de fácil lectura, sin barreras técnicas que impidan su acceso y deberá corresponder en un todo a aquella que repose en la base de datos.<br /><br />
                        El Gobierno Nacional establecerá la forma en la cual los responsables del tratamiento y encargados del tratamiento deberán suministrar la información del titular, atendiendo a la naturaleza del dato personal.<br /><br />
                        <label className='negrita'>Deber de informar al Titular.</label> El responsable del tratamiento, al momento de solicitar al titular la autorización, deberá informarle de manera clara y expresa lo siguiente:<br /><br />
                        •	El tratamiento al cual serán sometidos sus datos personales y la finalidad del mismo;<br />
                        •	El carácter facultativo de la respuesta a las preguntas que le sean hechas, cuando estas versen sobre datos sensibles o sobre los datos de las niñas, niños y adolescentes.<br />
                        •	Los derechos que le asisten como titular.<br />
                        •	La identificación, dirección física o electrónica y teléfono del responsable del tratamiento.<br /><br />

                        El responsable del tratamiento deberá conservar prueba del cumplimiento de lo previsto en el presente artículo y, cuando el Titular lo solicite, entregarle copia de esta.<br /><br />
                        Personas a quienes se les puede suministrar la información. La información que reúna las condiciones establecidas en la ley podrá suministrarse a las siguientes personas:<br /><br />
                        a.	A los Titulares, sus causahabientes o sus representantes legales;<br />
                        b.	A las entidades públicas o administrativas en ejercicio de sus funciones legales o por orden judicial;<br />
                        c.	A los terceros autorizados por el Titular o por la ley.<br /><br />
                    </div>

                    <h3 className='negrita'>DEBERES DE LOS RESPONSABLES DEL TRATAMIENTO Y ENCARGADOS DEL TRATAMIENTO</h3>

                    <div className='dateInforme'>

                        SOCIEDAD FERRETERA DE COMERCIO S.A.S. cumple los siguientes deberes:<br /><br />
                        a.	Garantiza al titular, en todo tiempo, el pleno y efectivo ejercicio del derecho de hábeas data.<br />
                        b.	Solicita y conserva, en las condiciones previstas en la ley, copia de la respectiva autorización otorgada por el titular.<br />
                        c.	Informa debidamente al titular sobre la finalidad de la recolección y los derechos que le asisten por virtud de la autorización otorgada.<br />
                        d.	Conserva la información bajo las condiciones de seguridad necesarias para impedir su adulteración, pérdida, consulta, uso o acceso no autorizado o fraudulento.<br />
                        e.	Garantiza que la información que se suministre al encargado del tratamiento sea veraz, completa, exacta, actualizada, comprobable y comprensible.<br />
                        f.	Actualiza la información, comunicando de forma oportuna al encargado del tratamiento, todas las novedades respecto de los datos que previamente le haya suministrado y adoptar las demás medidas necesarias para que la información suministrada a este se mantenga actualizada.<br />
                        g.	Rectifica la información cuando sea incorrecta y comunica lo pertinente al encargado del tratamiento.<br />
                        h.	Suministra al encargado del tratamiento, según el caso, únicamente datos cuyo tratamiento esté previamente autorizado de conformidad con lo previsto en la ley.<br />
                        a.	Tramita las consultas y reclamos formulados en los términos señalados en la ley.<br />
                        j.	Adopta un manual interno de políticas y procedimientos para garantizar el adecuado cumplimiento de la ley y en especial, para la atención de consultas y reclamos;<br />
                        k.	Informa al Encargado del Tratamiento cuando determinada información se encuentra en discusión por parte del Titular, una vez se haya presentado la reclamación y no haya finalizado el trámite respectivo;<br />
                        xx.	Informa a solicitud del Titular sobre el uso dado a sus datos;<br />
                        lllllllll.	Informar a la autoridad de protección de datos cuando se presenten violaciones a los códigos de seguridad y existan riesgos en la administración de la información de los titulares.<br />
                        n.	Cumplir las instrucciones y requerimientos que imparta la Superintendencia de Industria y Comercio.<br /><br />
                    </div>

                    <h3 className='negrita'>PROCESO PARA CONSULTAS Y RECLAMOS</h3>

                    <div className='dateInforme'>
                        <label className='negrita'>CONSULTA</label><br /><br />
                        Los titulares o sus causahabientes podrán consultar la información personal del Titular que repose en cualquier base de datos, sea esta del sector público o privado. El responsable del tratamiento o encargado del tratamiento deberán suministrar a estos toda la información contenida en el registro individual o que esté vinculada con la identificación del titular.<br /><br />
                        La consulta se formulará por el medio habilitado por el responsable del tratamiento o encargado del tratamiento, siempre y cuando se pueda mantener prueba de esta. La consulta será atendida en un término máximo de diez (10) días hábiles contados a partir de la fecha de recibo de la misma. Cuando no fuere posible atender la consulta dentro de dicho término, se informará al interesado, expresando los motivos de la demora y señalando la fecha en que se atenderá su consulta, la cual en ningún caso podrá superar los cinco (5) días hábiles siguientes al vencimiento del primer término.<br /><br />

                        Las disposiciones contenidas en leyes especiales o los reglamentos expedidos por el Gobierno Nacional podrán establecer términos inferiores, atendiendo a la naturaleza del dato personal.<br /><br />
                        <label className='negrita'>RECLAMOS</label><br /><br />

                        El titular o sus causahabientes que consideren que la información contenida en una base de datos debe ser objeto de corrección, actualización o supresión, o cuando adviertan el presunto incumplimiento de cualquiera de los deberes contenidos en esta ley, podrán presentar un reclamo ante el responsable del tratamiento o el Encargado del tratamiento el cual será tramitado bajo las siguientes reglas:<br /><br />
                        1. El reclamo se formulará mediante solicitud dirigida al responsable del tratamiento o al encargado del tratamiento, con la identificación del titular, la descripción de los hechos que dan lugar al reclamo, la dirección, y acompañando los documentos que se quiera hacer valer. Si el reclamo resulta incompleto, se requerirá al interesado dentro de los cinco (5) días siguientes a la recepción del reclamo para que subsane las fallas. Transcurridos dos (2) meses desde la fecha del requerimiento, sin que el solicitante presente la información requerida, se entenderá que ha desistido del reclamo.<br /><br />
                        En caso de que quien reciba el reclamo no sea competente para resolverlo, dará traslado a quien corresponda en un término máximo de dos (2) días hábiles e informará de la situación al interesado.<br /><br />
                        Una vez recibido el reclamo completo, se incluirá en la base de datos una leyenda que diga "reclamo en trámite" y el motivo del mismo, en un término no mayor a dos (2) días hábiles. Dicha leyenda deberá mantenerse hasta que el reclamo sea decidido.<br /><br />
                        El término máximo para atender el reclamo será de quince (15) días hábiles contados a partir del día siguiente a la fecha de su recibo.<br /><br />
                        Cuando no fuere posible atender el reclamo dentro de dicho término, se informará al interesado los motivos de la demora y la fecha en que se atenderá su reclamo, la cual en ningún caso podrá superar los ocho (8) días hábiles siguientes al vencimiento del primer término.<br /><br />
                        Requisito de procedibilidad. El titular o causahabiente sólo podrá elevar queja ante la Superintendencia de Industria y Comercio una vez haya agotado el trámite de consulta o reclamo ante el responsable del Tratamiento o Encargado del Tratamiento.<br /><br />

                        <h3 className='negrita'>PROCESOS PARA SEGURIDAD DE LA INFORMACION</h3><br />
                        SOCIEDAD FERRETERA DE COMERCIO S.A.S.  Tiene consagrados en sus sistemas la seguridad de la información que establece la obligatoriedad de:<br /><br />
                        •	Identificar mediante credenciales el ingreso al sistema<br />
                        •	Los roles y permisos están establecidos por nivel jerárquico y de acuerdo a las funciones de cada uno<br />
                        •	De acuerdo a lo anterior, se tiene establecido los mecanismos que aseguran la confidencialidad y resguardo de la información de terceros<br />
                        •	El sistema permite dejar un rastreo del usuario que realiza consultas/modificaciones dejando un registro en el sistema<br />
                        •	Se toman las medidas restrictivas para acceso al ciberespacio con los mecanismos de filtros, antivirus y firewalls requeridos<br />
                        •	Se tiene una base de datos contentiva de la información pertinente de las contrapartes, con el debido control por campo para garantizar un nivel de confianza razonable en sus niveles de población y consistencia<br /><br />

                        En general, de forma tecnológica y mediante capacitaciones se garantiza la seguridad, calidad y resguardo de la información de SOCIEDAD FERRETERA DE COMERCIO S.A.S.<br /><br />
                    </div>

                    <h3 className='negrita'>TRANSPARENCIA DE DATOS A TERCEROS PAISES</h3>

                    <div className='dateInforme'>

                        Artículo 26. Prohibición. Se prohíbe la transferencia de datos personales de cualquier tipo a países que no proporcionen niveles adecuados de protección de datos. Se entiende que un país ofrece un nivel adecuado de protección de datos cuando cumpla con los estándares fijados por la Superintendencia de Industria y Comercio sobre la materia, los cuales en ningún caso podrán ser inferiores a los que la ley exige a sus destinatarios.<br /><br />
                        Esta prohibición no regirá cuando se trate de:<br /><br />
                        a) Información respecto de la cual el titular haya otorgado su autorización expresa e inequívoca para la transferencia.<br />
                        b) Intercambio de datos de carácter médico, cuando así lo exija el tratamiento del titular por razones de salud o higiene pública.<br />
                        c) Transferencias bancarias o bursátiles, conforme a la legislación que les resulte aplicable.<br />
                        d) Transferencias acordadas en el marco de tratados internacionales en los cuales la República de Colombia sea parte, con fundamento en el principio de reciprocidad.<br />
                        e) Transferencias necesarias para la ejecución de un contrato entre el titular y el responsable del tratamiento, o para la ejecución de medidas precontractuales siempre y cuando se cuente con la autorización del titular;<br />
                        f) Transferencias legalmente exigidas para la salvaguardia del interés público, o para el reconocimiento, ejercicio o defensa de un derecho en un proceso judicial.<br /><br />
                        En los casos no contemplados como excepción en el presente artículo, corresponderá a la Superintendencia de Industria y Comercio, proferir la declaración de conformidad relativa a la transferencia internacional de datos personales. Para el efecto, el Superintendente queda facultado para requerir información y adelantar las diligencias tendientes a establecer el cumplimiento de los presupuestos que requiere la viabilidad de la operación.<br />
                        Las disposiciones contenidas en el presente artículo serán aplicables para todos los datos personales, incluyendo aquellos contemplados en la Ley 1266 de 2008.<br /><br />
                    </div>

                    <h3 className='negrita'>REGIMEN SANCIONATORIO</h3>

                    <div className='dateInforme'>
                        <label className='negrita'>TRÁMITE.</label><br />

                        La Superintendencia de Industria y Comercio, una vez establecido el incumplimiento de las disposiciones de la ley por parte del responsable del tratamiento o el encargado del tratamiento, adoptará las medidas o impondrá las sanciones correspondientes.<br /><br />
                        En lo no reglado por la ley y los procedimientos correspondientes se seguirán las normas pertinentes del Código Contencioso Administrativo.<br /><br />
                        La Superintendencia de Industria y Comercio podrá imponer a los responsables del tratamiento y encargados del tratamiento las siguientes sanciones:<br /><br />
                        •	Multas de carácter personal e institucional hasta por el equivalente de dos mil (2.000) salarios mínimos mensuales legales vigentes al momento de la imposición de la sanción. Las multas podrán ser sucesivas mientras subsista el incumplimiento que las originó.<br />
                        •	Suspensión de las actividades relacionadas con el tratamiento hasta por un término de seis (6) meses. En el acto de suspensión se indicarán los correctivos que se deberán adoptar.<br />
                        •	Cierre temporal de las operaciones relacionadas con el Tratamiento una vez transcurrido el término de suspensión sin que se hubieren adoptado los correctivos ordenados por la Superintendencia de Industria y Comercio.<br />
                        •	Cierre inmediato y definitivo de la operación que involucre el Tratamiento de datos sensibles.<br /><br />
                        Las sanciones indicadas en el presente artículo sólo aplican para las personas de naturaleza privada. En el evento en el cual la Superintendencia de Industria y Comercio advierta un presunto incumplimiento de una autoridad pública a las disposiciones de la presente ley, remitirá la actuación a la Procuraduría General de la Nación para que adelante la investigación respectiva.<br /><br />
                        Criterios para graduar las sanciones. Las sanciones por infracciones a las que se refieren el artículo anterior, se graduarán atendiendo los siguientes criterios, en cuanto resulten aplicables:<br /><br />
                        •	La dimensión del daño o peligro a los intereses jurídicos tutelados por la ley;<br />
                        •	El beneficio económico obtenido por el infractor o terceros, en virtud de la comisión de la infracción;<br />
                        •	La reincidencia en la comisión de la infracción;<br />
                        •	La resistencia, negativa u obstrucción a la acción investigadora o de vigilancia de la Superintendencia de Industria y Comercio;<br />
                        •	La renuencia o desacato a cumplir las órdenes impartidas por la Superintendencia de Industria y Comercio;<br />
                        •	El reconocimiento o aceptación expresos que haga el investigado sobre la comisión de la infracción antes de la imposición de la sanción a que hubiere lugar.<br />
                    </div>

                </p>
                <h3 className='negrita'><a id='manual' target={'new'} href={manual}>MANUAL DE POLITICAS Y PROCESOS DE PROTECCION DE DATOS</a></h3>
            </Container>
            <Footer />
        </>
    );
}

export { ProteccionDatos };