import React, { useState, useEffect } from "react";
import axios from 'axios';
import Cookies from 'universal-cookie';
import moment from 'moment';
import * as Icon from 'react-bootstrap-icons';
import { Header } from "../components/Header";
import { Container, Row, Col, Form, Table, Button, Modal, Alert } from "react-bootstrap";
import { Footer } from "../components/Footer";

function EstadoPedidos() {

    const [pedidos, setPedidos] = useState([]);
    const [itemsPedido, setItemsPedido] = useState([]);
    const [fechas, setFechas] = useState({ fecha_inicial: moment().format('YYYY-MM-DD'), fecha_final: moment().format('YYYY-MM-DD') });
    const [lgShow, setLgShow] = useState(false);
    const [mensajeValidacionArchivo, setMensajeValidacionArchivo] = useState('');
    const [fileSoportePago, setFileSoportePago] = useState([]);
    const [showModalSoportePago, setShowModalSoportePago] = useState(false);
    const [idPedido, setIdPedido] = useState('')
    const [visualizacionCatalogo, setVisualizacionCatalogo] = useState('none');
    const [transaccionShow, setTransaccionShow] = useState(false);
    const [pago, setPago] = useState({
        "status": {
            "status": '',
            "message": '',
        },
        "reference": '',
        "descripcion": '',
        "total": '',
        "usuario": '',
        "moneda": '',
    });

    const estadoSesion = {
        '': '',
        'PENDING': 'Pendiente',
        'APPROVED': 'Aprobada',
        'REJECTED': 'Rechazada'
    }

    const formatoPesosColombianos = new Intl.NumberFormat('es-CO', {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 0
    });

    const formatoPorcentaje = (num) => {
        return Number(num / 100).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2 });
    }

    const subirPdfSoportePago = async () => {

        const cookies = new Cookies();
        const accessToken = cookies.get('accesToken');

        if (fileSoportePago.length > 0) {
            document.getElementById("fileSoportePago").classList.remove("is-invalid");
            document.getElementById("fileSoportePago").classList.add("is-valid");
            setMensajeValidacionArchivo("");
            try {
                const url = process.env.REACT_APP_SERVER + '/api/v1/pedidos/consult/codPaidSupport';
                const { data } = await axios.post(url);
                let codSoporte = data;

                let formData = new FormData();
                for (let value of fileSoportePago) {
                    formData.append("formData", value);
                }

                const response = await axios.post(process.env.REACT_APP_SERVER + "/api/v1/pdf/soporte_pago", formData, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    }
                });
                cookies.set('accessToken', response.data.accessToken, { path: "/" });

                let arrayName = fileSoportePago[0].name.split('.');
                let extension = "." + arrayName[arrayName.length - 1];

                if (response.data.result) {
                    let objPedidoSoportePago = {
                        id_pedido: idPedido,
                        codigo_soporte_pago: codSoporte.cod,
                        url_soporte_pago: "SoportePago-" + codSoporte.cod + extension
                    };

                    const url = process.env.REACT_APP_SERVER + '/api/v1/pedidos/add/paidSupport';
                    const { data } = await axios.post(url, objPedidoSoportePago);
                    if (data.result) {
                        queryPedidos(null);
                        const urlSoporte = process.env.REACT_APP_SERVER + '/api/v1/pedidos/AlertSoporte';
                        await axios.post(urlSoporte, { id_pedido: idPedido }, {
                            headers: {
                                'Authorization': `Bearer ${accessToken}`
                            }
                        });
                        setShowModalSoportePago(false)
                    }

                } else {
                    setMensajeValidacionArchivo(response.data.message);
                }

            } catch (err) {
                console.log('Ocurrió error al subir el archivo: ' + err);
            }
        } else {
            document.getElementById("fileSoportePago").classList.remove("is-valid");
            document.getElementById("fileSoportePago").classList.add("is-invalid");
            setMensajeValidacionArchivo("Debe de seleccionar un archivo");
        }

    }

    const handleFechas = e => {
        const { name, value } = e.target

        if (name === 'fecha_inicial') {
            if (moment(value) > moment(fechas.fecha_final)) {
                alert('La fechas inicial no puede ser mayor a la fecha final')
            } else {
                setFechas({
                    ...fechas,
                    [name]: value
                });
            }
        } else {
            if (moment(value) < moment(fechas.fecha_inicial)) {
                alert('La fecha final no puede ser menor a la fecha inicial')
            } else {
                setFechas({
                    ...fechas,
                    [name]: value
                });
            }
        }
    }

    const handleModalItems = async (id_pedido) => {
        const cookies = new Cookies();
        const accessToken = cookies.get('accesToken');
        const user = cookies.get('user')

        setLgShow(true);
        let lista = user.lista_de_precios;
        try {
            const url = process.env.REACT_APP_SERVER + '/api/v1/pedidosItems/items';
            const body = { id_pedido, lista };
            const { data } = await axios.post(url, body, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });

            setItemsPedido(data);
        } catch (error) {
            console.log('Error en la consulta de items pedidos: ' + error.message)
        }
    }

    const handlePDFSoportePago = e => {
        let arrayAux = [];
        for (let index = 0; index < e.target.files.length; index++) {
            arrayAux.push(e.target.files[index]);
        }

        setFileSoportePago(arrayAux);
    }

    const handleSoportePago = (id_pedido) => {
        setIdPedido(id_pedido);
        setShowModalSoportePago(true)
    }

    const handleModalItemsCerrar = () => {
        setLgShow(false);
        setItemsPedido([]);
    }

    const handleTransaccion = async (requestId) => {

        if (requestId) {
            const cookies = new Cookies();
            const accessToken = cookies.get('accesToken');
            const url = process.env.REACT_APP_SERVER + '/api/v1/pedidos/authPlacetoPay';
            const { data: autorizacion } = await axios.post(url, { requestId: requestId }, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });

            if (autorizacion.result) {
                let body = {
                    "auth": {
                        "login": autorizacion.data.login,
                        "tranKey": autorizacion.data.tranKey,
                        "nonce": autorizacion.data.nonce,
                        "seed": autorizacion.data.seed
                    }
                }

                const urlCheckout = process.env.REACT_APP_BASE_URL_GOU+'/api/session/' + requestId;
                const { data, status } = await axios.post(urlCheckout, body);

                if (status === 200) {
                    let fecha = moment(status.date).format('YYYY-MM-DD, h:mm:ss');
                    if (data.payment) {
                        if (data.payment.length > 0) {
                            fecha = moment(data.payment[0].status.date).format('YYYY-MM-DD, h:mm:ss');
                        }
                    }
                    let pagoAux = {
                        "status": {
                            "status": estadoSesion[data.status.status],
                            "message": data.status.message
                        },
                        "requestId": requestId,
                        "date": fecha,
                        "reference": data.request.payment.reference,
                        "descripcion": data.request.payment.description,
                        "total": data.request.payment.amount.total,
                        "usuario": data.request.buyer.email,
                        "moneda": data.request.payment.amount.currency,
                    }
                    setPago(pagoAux);
                    setTransaccionShow(true);
                }
            }
        }
    }

    const handleModalTransaccionCerrar = () => {
        setTransaccionShow(false);
        setPago({
            "status": {
                "status": '',
                "message": '',
            },
            "reference": '',
            "descripcion": '',
            "total": '',
            "usuario": '',
            "moneda": '',
        });
    }

    const queryPedidos = async (fechas2) => {
        const cookies = new Cookies();
        const accessToken = cookies.get('accesToken')
        const user = cookies.get('user')

        if (user.lista_de_precios === '001' || user.lista_de_precios === '014' || user.lista_de_precios === '010') { setVisualizacionCatalogo('revert'); }

        try {
            const url = process.env.REACT_APP_SERVER + '/api/v1/pedidos/estadoPedido/cliente'
            const { data } = await axios.post(url, fechas2, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });
            if(data.result) {
                setPedidos(data.datos);
            }
        } catch (err) {
            console.log("Error en la consulta de estado pedido: " + err)
        }
    }

    const modalItems = <Modal
        size="lg"
        show={lgShow}
        onHide={() => setLgShow(false)}
        className="text-center"
        aria-labelledby="example-modal-sizes-title-lg"
    >
        <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
                Items del Pedido
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Table responsive bordered>
                <thead>
                    <tr>
                        <th>ITEM</th>
                        <th>DESCRIPCIÓN</th>
                        <th>UM</th>
                        <th>CANTIDAD</th>
                        <th>IVA</th>
                        <th>DESCUENTO</th>
                        <th>VALOR FINAL</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        itemsPedido.map((item, i) =>
                            <tr key={i}>
                                <td>{item.item} {item.descripcion_item ? '' : 'BAJO PEDIDO'}</td>
                                <td>{item.descripcion_item ? item.descripcion_item : item.descripcion_item_pedido}</td>
                                <td>{item.um}</td>
                                <td>{Math.round(item.cantidad)}</td>
                                <td>{formatoPorcentaje(item.iva)}</td>
                                <td>{formatoPorcentaje(item.descuento)}</td>
                                <td>{formatoPesosColombianos.format(Math.round(item.valor_final))}</td>
                            </tr>
                        )
                    }
                </tbody>
            </Table>
        </Modal.Body>
        <Modal.Footer>
            <Button onClick={() => handleModalItemsCerrar()}>Close</Button>
        </Modal.Footer>
    </Modal>

    const modalFileSoporte = <Modal
        show={showModalSoportePago}
        backdrop="static"
        keyboard={false}
        onHide={() => setShowModalSoportePago(false)}
    >
        <Modal.Header closeButton>
            <Modal.Title>Subir Soporte Pago</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form.Control type="file" accept="application/pdf, .jpg, .jpeg" id="fileSoportePago" onChange={handlePDFSoportePago} />
            {mensajeValidacionArchivo !== "" && <p className="text-danger">{mensajeValidacionArchivo}</p>}
        </Modal.Body>
        <Modal.Footer>
            <Button onClick={subirPdfSoportePago} variant="outline-success">Subir</Button>
            <Button onClick={() => setShowModalSoportePago(false)} variant="outline-danger">Cancelar</Button>
        </Modal.Footer>
    </Modal>

    useEffect(() => {
        queryPedidos(fechas);
    }, [fechas])

    return (
        <>
            <Header />
            <br />
            <Container>
                <Row className="mb-4" sm={6}>
                    <Col sm={6}>
                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                            <Form.Label column sm="2">
                                Fecha Inicio
                            </Form.Label>
                            <Col sm="5">
                                <Form.Control max={fechas.fecha_final} name="fecha_inicial" value={fechas.fecha_inicial} type="date" onChange={handleFechas} />
                            </Col>
                        </Form.Group>
                    </Col>
                    <Col sm={6}>
                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                            <Form.Label column sm="2">
                                Fecha Final
                            </Form.Label>
                            <Col sm="5">
                                <Form.Control min={fechas.fecha_inicial} name="fecha_final" value={fechas.fecha_final} type="date" onChange={handleFechas} />
                            </Col>
                        </Form.Group>

                    </Col>
                </Row>
                <Row>
                    {
                        pedidos.length > 0 ?
                            <>
                                <Table responsive bordered className="text-center">
                                    <thead>
                                        <tr>
                                            <th>PEDIDO</th>
                                            <th>ITEMS</th>
                                            <th>FECHA</th>
                                            <th>ESTADO PEDIDO</th>
                                            <th style={{ display: visualizacionCatalogo }}>MEDIO DE PAGO</th>
                                            <th style={{ display: visualizacionCatalogo }}>ESTADO TRANSACCIÓN</th>
                                            <th>VALOR</th>
                                            <th style={{ display: visualizacionCatalogo }}>SOPORTE PAGO</th>
                                            {/* <th style={{display: visualizacionCatalogo}}>ESTADO ENTREGA</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            pedidos.map((item, i) =>
                                                <tr key={i}>
                                                    <td>{item.numero_pedido}</td>
                                                    <td>
                                                        {
                                                            <Button variant="outline-warning" onClick={() => handleModalItems(item.id_pedido)}>
                                                                <Icon.Basket2 />
                                                            </Button>

                                                        }
                                                        {modalItems}
                                                    </td>
                                                    <td>{moment(item.fecha_creacion).format('YYYY-MM-DD HH:mm:ss')}</td>
                                                    <td>{item.estado_pedido}</td>
                                                    <td style={{ display: visualizacionCatalogo }}>{item.medio_de_pago}</td>
                                                    <td style={{ display: visualizacionCatalogo }}>{item.campo === null ? 'No Aplica' : item.campo.split('-')[0]}
                                                        {item.campo === null ? '' :
                                                            <Button variant="outline-warning" onClick={() => handleTransaccion(item.id_sesion_gou)}>
                                                                <Icon.InfoCircle />
                                                            </Button>
                                                        }

                                                    </td>
                                                    <td>{formatoPesosColombianos.format(Math.round(item.valor_final))}</td>
                                                    <td style={{ display: visualizacionCatalogo }}>
                                                        {
                                                            item.url_soporte_pago === null
                                                                ?
                                                                <Button variant="outline-warning" onClick={() => handleSoportePago(item.id_pedido)}>
                                                                    <Icon.FileArrowUp />
                                                                </Button>
                                                                :
                                                                <a href={process.env.REACT_APP_SERVER_IMAGE + "soporte_pagos/" + item.url_soporte_pago} target="_blank" download rel="noreferrer">{item.url_soporte_pago}</a>
                                                        }
                                                        {modalFileSoporte}
                                                    </td>
                                                    {/* <td style={{display: visualizacionCatalogo}}>{item.estado_entrega}</td> */}
                                                </tr>

                                            )
                                        }
                                    </tbody>
                                </Table>
                                <Modal
                                    size="md"
                                    show={transaccionShow}
                                    onHide={() => handleModalTransaccionCerrar(false)}
                                    className="text-center"
                                    aria-labelledby="example-modal-sizes-title-lg"
                                >
                                    <Modal.Header closeButton>
                                        <Modal.Title id="example-modal-sizes-title-lg">
                                            Estado Transacción
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Table responsive bordered className="text-center">
                                            <tbody>
                                                <tr>
                                                    <td><b>Fecha y Hora:</b></td>
                                                    <td>{pago.date}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Estado de la transacción:</b></td>
                                                    <td><b>{pago.status.status}</b></td>
                                                </tr>
                                                <tr>
                                                    <td><b>Referencia de la transacción:</b></td>
                                                    <td>{pago.reference}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Usuario:</b></td>
                                                    <td>{pago.usuario}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Valor total:</b></td>
                                                    <td>{formatoPesosColombianos.format(pago.total)}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Moneda:</b></td>
                                                    <td>{pago.moneda}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Descripción:</b></td>
                                                    <td><em>{pago.descripcion}</em></td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button onClick={() => handleModalTransaccionCerrar()}>Cerrar</Button>
                                    </Modal.Footer>
                                </Modal>
                            </>
                            :
                            <Alert style={{ width: '500px' }} className="mx-auto text-center">
                                <b>No tienes pedidos. Prueba con otro rango de fechas</b>
                            </Alert>
                    }
                </Row>
            </Container>
            <Footer />
        </>
    )
}

export { EstadoPedidos };