import axios from 'axios';
import Cookies from 'universal-cookie';
import ReactDOM from 'react-dom/client';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import Slider from "react-slick";
import { Header } from '../components/Header';
import { Footer } from '../components/Footer';
import { CartCheckFill, ListUl, Truck } from 'react-bootstrap-icons';
import { Row, Col, Container, Form, Pagination, Card, Button, Spinner, Offcanvas, Accordion, Modal } from 'react-bootstrap';

const CatalogoHUjueta = () => {
    const navigate = useNavigate();
    const Location = useLocation();
    const cookies = new Cookies();
    const [cantidad, setCantidad] = useState(1);
    const [objItem, setObjItem] = useState(null);
    const [tituloMensaje, setTituloMensaje] = useState(null);
    const [mensajeResult, setMensajeResult] = useState('');
    const [validacionAgregarCarrito, setValidacionAgregarCarrito] = useState(false);
    const [showModalResult, setShowModalResult] = useState(false);
    const [showModalAgregar, setShowModalAgregar] = useState(false);
    const [showCategorias, setShowCategorias] = useState(false);
    const [showCanvasCategorias, setShowCanvasCategorias] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [rangoFinal, setRangoFinal] = useState(12);
    const [imagenBanner, setImagenBanner] = useState([]);
    const [imagenWhatsapp, setImagenWhatsapp] = useState([]);
    const [productos, setProductos] = useState([]);
    const [totalPaginas, setTotalPaginas] = useState(0);
    const [filtroProductos, setFiltroProductos] = useState([]);
    const [arrayCategorias, setArrayCategorias] = useState([]);
    const [categorias, setCategorias] = useState([]);
    const [tituloBusqueda, setTituloBusqueda] = useState('');
    const [paginaActual, setPaginaActual] = useState(1);
    const [listaPrecios, setListaPrecios] = useState('014');
    const imagenPublicidad = process.env.REACT_APP_SERVER_IMAGE + 'estaticas/';

    var settingsMarcas = {
        dots: false,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const arrayMarcas = [
        {
            imagen: process.env.REACT_APP_SERVER_IMAGE + 'marcas/marca14.webp',
            nombre: 'Marca 14'
        },
        {
            imagen: process.env.REACT_APP_SERVER_IMAGE + 'marcas/marca15.webp',
            nombre: 'Marca 15'
        },
        {
            imagen: process.env.REACT_APP_SERVER_IMAGE + 'marcas/marca16.webp',
            nombre: 'Marca 16'
        },
        {
            imagen: process.env.REACT_APP_SERVER_IMAGE + 'marcas/marca17.webp',
            nombre: 'Marca 17'
        },
    ];

    const handleCategoryChange = (categoria) => {
        let categoriasAux = [];
        if (categorias.includes(categoria)) {
            categoriasAux = categorias.filter((c) => c !== categoria);
        } else {
            categoriasAux = [categoria];
        }
        setCategorias(categoriasAux);
        if (categoriasAux.length > 0) {
            const filtroCategoria = productos.filter((producto) =>
                categoriasAux.some((categoria) => producto.category === categoria)
            );
            setFiltroProductos(filtroCategoria);
            setTotalPaginas(1);
        } else {
            const paginaInicio = (paginaActual - 1) * rangoFinal;
            const paginaFinal = paginaInicio + rangoFinal;
            setFiltroProductos(productos.slice(paginaInicio, paginaFinal));
            setTotalPaginas(Math.ceil(productos.length / rangoFinal));
        }
        setShowCanvasCategorias(false)
    };

    const formatoPesosColombianos = useMemo(() => {
        return new Intl.NumberFormat('es-CO', {
            style: 'currency',
            currency: 'COP',
            minimumFractionDigits: 0,
        });
    }, []);

    const handleRangoFinal = useCallback((e) => {
        setRangoFinal(parseInt(e.target.value));
    }, []);

    const fetchData = useCallback(async () => {
        setIsLoading(true);
        setTituloBusqueda('');
        const cookies = new Cookies();
        const accessToken = cookies.get('accesToken');
        const urlCliente = process.env.REACT_APP_SERVER + '/api/v1/validarListaPrecio';
        const respCliente = await axios.get(urlCliente, {
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        });
        const { data } = await axios.get(process.env.REACT_APP_SERVER + '/api/v1/items/ujueta/list', {
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        });
        if (data.result) {
            const resultProductos = data.productos;
            if (respCliente.data.lista_de_precios === '001') {
                const { data: resultCategorias } = await axios.get(process.env.REACT_APP_SERVER + '/api/v1/items/ujueta/sublineas/list', {
                    headers: {
                        "Authorization": `Bearer ${accessToken}`
                    }
                });
                for (let i = 0; i < resultProductos.length; i++) {
                    const product = resultProductos[i];
                    let descuento = 0;
                    const buscarCategoria = resultCategorias.categorias.find(
                        (categoria) => categoria.descripcion_categoria.toUpperCase() === product.category.toUpperCase()
                    );
                    if (buscarCategoria) {
                        descuento = buscarCategoria.descuento_categoria;
                    }
                    resultProductos[i] = { ...resultProductos[i], descuento: descuento };
                }
            }
            setProductos(resultProductos);
            setTotalPaginas(Math.ceil(resultProductos.length / rangoFinal));
            setIsLoading(false);
            setShowCategorias(true);
        }

    }, [rangoFinal]);

    const handlePagination = useCallback((pagina) => {
        setPaginaActual(pagina);
        window.scroll(0, 0);
    }, []);

    const generatePageNumbers = useCallback(() => {
        const numerosPagina = [];
        const paginaInicio = Math.max(1, paginaActual - 2);
        const paginaFinal = Math.min(totalPaginas, paginaInicio + 4);
        for (let i = paginaInicio; i <= paginaFinal; i++) {
            numerosPagina.push(i);
        }
        return numerosPagina;
    }, [paginaActual, totalPaginas]);

    const validacionAgregarProducto = async (propiedades) => {
        try {
            let token = cookies.get('accesToken');
            let objItemAux = '';
            const url = process.env.REACT_APP_SERVER + '/requireAuth/routes';
            const response = await axios.get(url, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            const data = response.data;
            if (data.validation === 1) {
                let descuento = 0;
                listaPrecios === '001' && (descuento = propiedades.descuento);
                objItemAux = {
                    item: propiedades.sku,
                    descripcion: propiedades.name,
                    um: 'UND',
                    precio: parseInt((propiedades.price * 1.19) * (1 - parseFloat(descuento) / 100)),
                    descuento_original: 0,
                    descuento: 0,
                    valor_final: parseInt((propiedades.price * 1.19) * (1 - parseFloat(descuento) / 100))
                };
            }
            setCantidad(1);
            setTituloMensaje("AGREGAR PRODUCTO");
            setObjItem(objItemAux);
            setValidacionAgregarCarrito(true);
        } catch (err) {
            console.log(err);
            if (err.response.status === 403 ||
                err.response.status === 401) {
                setTituloMensaje("Información");
            }
        }
        setShowModalAgregar(true);
    }

    const registrarProductoCarrito = async () => {
        const token = cookies.get('accesToken');
        const user = cookies.get('user');
        if (cantidad % 1 !== 0) {
            alert('La cantidad debe ser un número entero')
        } else if (cantidad <= 0) {
            alert('La cantidad debe ser mayor a 0')
        } else {
            try {
                const urlRegistrar = process.env.REACT_APP_SERVER + '/api/v1/shoppingCart/register';
                const body = { cantidad, objItem, sobrePedido: 'SI' };
                const responseRegistrar = await axios.post(urlRegistrar, body, {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                });
                const dataRegistrar = responseRegistrar.data;
                setMensajeResult(dataRegistrar["message"]);
                setShowModalAgregar(false);
                setShowModalResult(true);
                cookies.set('accesToken', dataRegistrar["accessToken"], { path: '/' });
                const urlCarrito = process.env.REACT_APP_SERVER + '/api/v1/shoppingCart/list';
                const responseCarrito = await axios.get(urlCarrito, {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                });
                const dataCarrito = responseCarrito.data[0];
                cookies.set('carrito', (dataCarrito.length));
                if (user.tipo_usuario === 'temporal') {
                    const root = ReactDOM.createRoot(
                        document.getElementById('numberCarTemporal')
                    );
                    const element = <span className="badge badge-dark">{cookies.get('carrito')}</span>
                    root.render(element);
                } else {
                    const root = ReactDOM.createRoot(
                        document.getElementById('numberCar')
                    );
                    const element = <span className="badge badge-dark">{cookies.get('carrito')}</span>
                    root.render(element);
                }
            } catch (err) {
                console.log(err);
                if (err.response.status === 403 ||
                    err.response.status === 401) {
                    setTituloMensaje("Información");
                }
            }
        }
    }

    useEffect(() => {
        setPaginaActual(1);
        const validacionInicial = async () => {
            const cookies = new Cookies();
            const accessToken = cookies.get('accesToken');
            const urlCliente = process.env.REACT_APP_SERVER + '/api/v1/validarListaPrecio';
            const respCliente = await axios.get(urlCliente, {
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                }
            });
            setListaPrecios(respCliente.data.lista_de_precios);
            if (respCliente.data.tipo_cliente_pagina === 'CATALOGO') {
                navigate('/inicioSesion', { state: { page: '/catalogoUjueta' } });
            } else {
                const { data: resultFiltroCategorias } = await axios.get(process.env.REACT_APP_SERVER + '/api/v1/items/ujueta/sublineas/listDistinct', {
                    headers: {
                        "Authorization": `Bearer ${accessToken}`
                    }
                });
                if (resultFiltroCategorias.result) {
                    setArrayCategorias(resultFiltroCategorias.categorias);
                }
                if (cookies.get('textoBuscar') !== undefined) {
                    setTituloBusqueda(cookies.get('textoBuscar'));
                    if (Location.state !== null) {
                        if (Location.state.searchType === 'Ujueta') {
                            setShowCategorias(false);
                            if (Location.state.limitPags > 0) {
                                const { data } = Location.state
                                if (respCliente.data.lista_de_precios === '001') {
                                    const { data: resultCategorias } = await axios.get(process.env.REACT_APP_SERVER + '/api/v1/items/ujueta/sublineas/list', {
                                        headers: {
                                            "Authorization": `Bearer ${accessToken}`
                                        }
                                    });
                                    for (let i = 0; i < data.length; i++) {
                                        const product = data[i];
                                        let descuento = 0;
                                        const buscarCategoria = resultCategorias.categorias.find(
                                            (categoria) => categoria.descripcion_categoria.toUpperCase() === product.category.toUpperCase()
                                        );
                                        if (buscarCategoria) {
                                            descuento = buscarCategoria.descuento_categoria;
                                        }
                                        data[i] = { ...data[i], descuento: descuento };
                                    }
                                }
                                setProductos(data);
                                setFiltroProductos(data);
                            } else {
                                setProductos([]);
                                setFiltroProductos([]);
                            }
                            setTotalPaginas(Math.ceil(Location.state.limitPags / rangoFinal));
                        } else {
                            fetchData(respCliente.data.lista_de_precios);
                        }
                    } else {
                        fetchData(respCliente.data.lista_de_precios);
                    }
                } else {
                    fetchData(respCliente.data.lista_de_precios);
                }
            }
        }
        validacionInicial();
        // eslint-disable-next-line
    }, [Location.state, fetchData]);

    useEffect(() => {
        const paginaInicio = (paginaActual - 1) * rangoFinal;
        const paginaFinal = paginaInicio + rangoFinal;
        setFiltroProductos(productos.slice(paginaInicio, paginaFinal));
    }, [productos, rangoFinal, paginaActual]);

    useEffect(() => {
        const accessToken = cookies.get('accesToken');
        const bannerIncicio = async () => {
            const urlBanner = process.env.REACT_APP_SERVER + '/api/v1/products/banner';
            const [dataBanner, dataBannerW] = await Promise.all([
                axios.post(urlBanner, { pagina: 'catalogo' }, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                    },
                }),
                axios.post(urlBanner, { pagina: 'whatsapp' }, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                    },
                }),
            ]);
            setImagenBanner(dataBanner.data);
            setImagenWhatsapp(dataBannerW.data);
        }
        bannerIncicio();
        // eslint-disable-next-line
    }, []);

    const settingsBanners = {
        autoplay: true,
        speed: 500,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <>
            <Header />
            <Container>
                <Row>
                    <Col sm={3} xs={12} >
                        <Row>
                            <Col xs={12} className='flechas'>
                                <Slider {...settingsBanners} >
                                    {
                                        imagenBanner !== []
                                            ?
                                            imagenBanner.map((fila, id) =>
                                                <div key={id} >
                                                    <a href={fila.telefono} target={'_new'} ><img loading='lazy' fallback={<p>Cargando</p>} src={imagenPublicidad + fila.pagina} alt="bannerPrincipal" className="img-fluid" style={{ maxHeight: 'max-content' }} id='imgBanner' /></a>
                                                </div>
                                            )
                                            :
                                            <label></label>
                                    }
                                </Slider>
                            </Col>
                        </Row>
                        <br></br>
                        <Row>
                            <Col xs={6} sm={7} md={12} lg={5}>
                                <Form.Select size="sm" onChange={handleRangoFinal} value={rangoFinal} className={'filtroSelect'}>
                                    <option value={12}>{12}</option>
                                    <option value={24}>{24}</option>
                                </Form.Select>
                            </Col>
                        </Row>
                        {showCategorias &&
                            <Row>
                                <br></br>
                                <Col className={'divCanvas'}>
                                    <div id="divOffcanvasCategorias">
                                        <Button variant="outline-warning" onClick={() => setShowCanvasCategorias(true)} style={{ margin: '10px 0px', fontSize: '16px' }}>
                                            <ListUl /> CATEGORIAS DE PRODUCTOS
                                        </Button>
                                        <Offcanvas show={showCanvasCategorias} onHide={() => setShowCanvasCategorias(false)}>
                                            <Offcanvas.Header closeButton />
                                            <Offcanvas.Body>
                                                <Accordion >
                                                    <Accordion.Item eventKey="0" >
                                                        <Accordion.Header className={'headerAcordion'}><h5 className='h5_titulosMenu' style={{ width: '100%' }}>CATEGORIAS DE PRODUCTOS</h5></Accordion.Header>
                                                        <Accordion.Body>
                                                            <Form>
                                                                {arrayCategorias.map((categoria, index) => (
                                                                    <Form.Check
                                                                        key={index}
                                                                        type="checkbox"
                                                                        label={categoria.category.toUpperCase()}
                                                                        checked={categorias.includes(categoria.category)}
                                                                        onChange={() => handleCategoryChange(categoria.category)}
                                                                    />
                                                                ))}
                                                            </Form>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                </Accordion>
                                            </Offcanvas.Body>
                                        </Offcanvas>
                                    </div>
                                    <div id="divAccordionCategorias">
                                        <Accordion>
                                            <Accordion.Item>
                                                <Accordion.Header className={'headerAcordion'}><h5 className='h5_titulosMenu' style={{ height: '35px', width: '100%' }}>CATEGORIAS DE PRODUCTOS</h5></Accordion.Header>
                                                <Accordion.Body>
                                                    <Form>
                                                        {arrayCategorias.map((categoria, index) => (
                                                            <Form.Check
                                                                key={index}
                                                                type="checkbox"
                                                                label={categoria.category.toUpperCase()}
                                                                checked={categorias.includes(categoria.category)}
                                                                onChange={() => handleCategoryChange(categoria.category)}
                                                            />
                                                        ))}
                                                    </Form>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </div>
                                </Col>
                            </Row>
                        }
                    </Col>
                    <Col sm={9} xs={12}>
                        <Row className='ps-5'>
                            <Col xs={12} sm={9}>
                                <b className='b_textProducto'>{tituloBusqueda === '' ? '' : 'Resultado de ' + tituloBusqueda}</b>
                            </Col>
                        </Row>
                        <Row className="align-items-center">
                            <Col className="carruselResponsive carruselMarca flechas">
                                <Slider {...settingsMarcas}>
                                    {
                                        arrayMarcas.map((item, i) =>
                                            <Row key={i}>
                                                <Col className='imgMarcas' >
                                                    <img loading='lazy' fallback={<p>Cargando</p>} id={i} className="mx-auto img-fluid imgMarca" src={item.imagen} alt={item.nombre} width={'90%'} height={'100%'} />
                                                </Col>
                                            </Row>
                                        )
                                    }
                                </Slider>
                                <br></br>
                            </Col>
                        </Row>

                        {isLoading ? (
                            <div className="d-flex justify-content-center" style={{ height: '35vh', alignItems: 'center' }}>
                                <Spinner animation="border" variant="warning" style={{ width: '100px', height: '100px' }} />
                            </div>
                        ) : (
                            <Row>
                                {
                                    filtroProductos.map((propiedades, id) => (
                                        <Col key={id} className='imgCatalogo'>
                                            <Container>
                                                <Card className="mx-auto cardProducto" style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)', borderRadius: '5px' }}>
                                                    <div className="divInfoAdicionalCard">
                                                        <div className='divNuevoUjueta'>BAJO PEDIDO</div>
                                                    </div>
                                                    <Link to={`/productSingleUjueta/${propiedades.sku}`} state={{ item_pedido: propiedades.sku }}>
                                                        <Card.Img loading='lazy' fallback={<p>Cargando</p>} variant="top" src={propiedades.thumbnail} className="imgProducto" id='imgProducto' />
                                                    </Link>
                                                    <Card.Body id="card">
                                                        <Card.Title style={{ lineHeight: '1', textTransform: 'uppercase' }}>
                                                            <Link to={`/productSingleUjueta/${propiedades.sku}`} state={{ item_pedido: propiedades.sku }} id='cardTitle'>
                                                                {propiedades.name !== null &&
                                                                    <small>{propiedades.name.length > 100 ?
                                                                        propiedades.name.substring(0, 100) + '...' :
                                                                        propiedades.name}</small>
                                                                }
                                                            </Link>
                                                        </Card.Title>
                                                        <Card.Text id='cardProducto'>
                                                            <small>Ref: {propiedades.itemCode}</small>
                                                            <br></br>
                                                            <small>Codigo: {propiedades.sku}</small>
                                                        </Card.Text>
                                                        {
                                                            parseInt(propiedades.onhand) > 0
                                                                ?
                                                                <p className='textStock'>Disponibilidad: {parseInt(propiedades.onhand)}</p>
                                                                :
                                                                <p className='textAgotado'>VALIDAR DISPONIBILIDAD</p>
                                                        }
                                                        <Row style={{ height: "50px" }}>
                                                            <Col md={12} lg={6} className="catalogoVertical">
                                                                <Row>
                                                                    <Col xs={12}>
                                                                        {parseFloat(propiedades.descuento) > 0 ?
                                                                            <b className="colorTexto precio">{formatoPesosColombianos.format(parseInt((propiedades.price * 1.19) * (1 - parseFloat(propiedades.descuento) / 100)))}</b>
                                                                            :
                                                                            <b className="colorTexto precio">{formatoPesosColombianos.format(parseInt(propiedades.price * 1.19))}</b>
                                                                        }
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col sm={12} className={'divExclusivo'}>
                                                                <small>   </small>
                                                            </Col>
                                                        </Row>
                                                        <Col xs={12} className={'divExclusivo text-danger'}>
                                                            <Truck /><strong><small> Entrega en 7 días hábiles</small></strong>
                                                        </Col>
                                                        <Card.Text className='textIvaIncluido'><small>Con iva incluido. COP</small></Card.Text>
                                                        <Button id='btnCatalogo' variant="outline-warning" style={{ width: '100%' }} disabled={parseInt(propiedades.onhand) <= 0} onClick={() => validacionAgregarProducto(propiedades)}>
                                                            <CartCheckFill />   Agregar al carrito
                                                        </Button>
                                                    </Card.Body>
                                                </Card>
                                            </Container>
                                        </Col>
                                    ))
                                }
                            </Row>)
                        }
                        <Row>
                            <Col className="mx-auto p-3" style={{ width: '300px' }}>
                                <Pagination className="mx-auto" style={{ width: '300px' }}>
                                    {paginaActual === 1 ? <div style={{ width: "40px" }}></div> : <Pagination.Prev onClick={() => handlePagination(paginaActual - 1)} />}
                                    {
                                        generatePageNumbers().map((item, i) =>
                                            <Pagination.Item key={i} onClick={() => handlePagination(item)} active={paginaActual === item}>{item}</Pagination.Item>
                                        )
                                    }
                                    {paginaActual === totalPaginas ? null : <Pagination.Next onClick={() => handlePagination(paginaActual + 1)} />}
                                </Pagination>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
            {
                imagenWhatsapp.map((fila, id) =>
                    <a key={id} className='btn-wsp' target={"_new"} href={fila.telefono} aria-label="Número de whatsApp de nuestros asesores">
                        <Card.Img className='btnWsp1' alt="75x75" src={imagenPublicidad + fila.pagina} />
                    </a>
                )
            }
            <Footer />
            <Modal
                show={showModalAgregar}
                backdrop="static"
                keyboard={false}
                onHide={() => setShowModalAgregar(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {tituloMensaje}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        validacionAgregarCarrito
                            ?
                            <Row>
                                <Col sm={12}>
                                    Ingrese la cantidad que desea comprar de este producto
                                    <br></br>
                                    <b>Item:</b> {objItem.descripcion}
                                    <br></br>
                                    <b>Código:</b> {objItem.item}
                                    <br></br>
                                    <b>UM:</b> {objItem.um}
                                    <br></br>
                                    <Form.Group as={Row} className="mb-3">
                                        <Form.Label column sm={4}>
                                            <b>Cantidad:</b>
                                        </Form.Label>
                                        <Col sm={8}>
                                            <Form.Control type="number" name="cantidad" placeholder="1" min="0" value={cantidad} onChange={(e) => setCantidad(e.target.value)} />
                                        </Col>
                                    </Form.Group>
                                    <div className="d-grid gap-2">
                                        <Button variant="outline-warning btnAgregar" type="submit" onClick={() => registrarProductoCarrito()}>Agregar al carrito</Button>
                                        <Button variant="outline-secondary" type="button" onClick={() => setShowModalAgregar(false)}>Cancelar</Button>
                                    </div>
                                </Col>
                            </Row>
                            :
                            <Row>
                                <Col sm={12}>
                                    Para agregar productos al carrito de compra se debe de iniciar sesión
                                    <br></br>
                                    <Link to='/inicioSesion' className='btn btn-outline-warning'>Iniciar sesión</Link>
                                </Col>
                            </Row>
                    }
                </Modal.Body>
            </Modal>
            <Modal
                show={showModalResult}
                backdrop="static"
                keyboard={false}
                onHide={() => setShowModalResult(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Información</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {mensajeResult}
                    <div className="d-grid gap-2">
                        <Button variant="outline-warning btnAgregar" type="submit" onClick={() => setShowModalResult(false)}>Continuar Comprando</Button>
                        <Link to='/carrito' className='btn btn-outline-secondary'>Ir al Carrito</Link>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};
export { CatalogoHUjueta };