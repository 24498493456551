import React, {useState } from 'react';
import { Link } from 'react-router-dom';
import { Footer } from '../components/Footer';
import {Container, Row, Col, Form, Button, InputGroup, Modal, Figure} from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import axios from 'axios';
import { useNavigate } from "react-router-dom";

function RecupararCredenciales () {

    const [validated, setValidated] = useState(false);
    const [objFormulario, setObjFormulario] = useState({
        email:""
    });

    const [mensaje, setMensaje] = useState();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    let imagenLogo = process.env.REACT_APP_SERVER_IMAGE+'estaticas/logo.webp';

    const changeFormulario = (e) => {
        let {name, value} = e.target;
        setObjFormulario({
            ...objFormulario,
            [name]:value
        });
    }

    let navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }else{
            const url = process.env.REACT_APP_SERVER + '/api/v1/customers/validarEmail';
            const response = await axios.post(url, objFormulario);
            const data = response.data;
            setMensaje(data["message"]);
            setShow(true);
            if(data.resultado){
                setTimeout(function(){
                    navigate('/inicioSesion');
                }, 1000);
            }
        }

        setValidated(true);
    };

    let imagenBanner = process.env.REACT_APP_SERVER_IMAGE+'estaticas/baner_principal-small2.webp';

    return (
        <>
        <Container fluid="md">
        <Row>
                <Col sm={7}>
                    <Link to='/'>
                        <Figure.Image
                            className='image-logo'
                            alt="171x180"
                            src={imagenLogo}
                        />
                    </Link>
                </Col>
            </Row>
            <Row style={{alignItems: 'center'}}>
                <Col sm={7}>
                    <Row className="m-3 p-3 border border-dark rounded mb-0">
                        <Row>
                            <Col sm={12}>
                                <h4>Restablecer Contraseña</h4>
                            </Col>
                        </Row>

                        <Row>
                            <Col sm={10} className="m-auto">
                                <label>Por favor ingresar el correo electrónico para restablecer la contraseña de acceso</label>
                                <br></br>
                                <br></br>

                                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text id="inputGroup-sizing-sm">
                                            <Icon.EnvelopeFill/> 
                                        </InputGroup.Text>
                                        <Form.Control type="email" name="email" placeholder="Ingrese el correo electrónico"  required onChange={changeFormulario}/>
                                    </InputGroup>
                                    <div className="d-grid gap-2">
                                        <Button variant="outline-warning" type="submit">Enviar</Button>
                                    </div>
                                </Form>
                            </Col>
                        </Row>
                    </Row>
                </Col>
                <Col sm={5} className="m-auto m-3 p-3 ">
                    <div style={{textAlign: 'center'}}>
                        <img src={imagenBanner} style={{width:"100%", height:"100%"}} alt="product" className="img-fluid" />
                    </div>
                </Col>
            </Row>

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{mensaje}</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        </Container>
        <Footer />
        </>
    );
}

export {RecupararCredenciales};