import React, { useEffect, useState } from 'react';
import { Footer } from '../components/Footer';
import { Container, Row, Col, Form, Button, Modal, Figure } from 'react-bootstrap';
import axios from 'axios';
import { Link, useLocation } from 'react-router-dom';
import '../sources/styles/stylesTexto.css';

function FormularioRegistroUsuarioDetal() {

    const location = useLocation();
    location.state = '/';
    const [validated, setValidated] = useState(false);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [mensajeValidacionTelefono, setMensajeValidacionTelefono] = useState();
    const [mensaje, setMensaje] = useState();
    let imagenLogo = process.env.REACT_APP_SERVER_IMAGE + 'estaticas/logo.webp';
    const [objFormulario, setObjFormulario] = useState({
        numero_identificacion: "",
        nombre: "",
        apellido_1: "",
        apellido_2: "",
        direccion: "",
        departamento: "",
        ciudad: "",
        barrio: "",
        telefono: "",
        email: "",
        terminos_condiciones: "No acepto",
        password_cliente: "planb",
        codigo_vendedor: "CAJE",
        condicion_de_pago: "001",
        tipo_de_cliente: "0003",
        grupo_descuento: "",
        lista_de_precios: "010",
        difusion_publicitaria_cliente: "",
        tipo_cliente_pagina: "DETAL",
        tipo_descuento_cliente: "",
        estado_cliente: "1",
        sucursal: "001"
    });
    const [arrayDepartamentos, setArrayDepartamentos] = useState([]);
    const [arrayCiudades, setArrayCiudades] = useState([]);
    const [arrayGrupoDescuento, setArrayGrupoDescuento] = useState([]);

    const consultarGrupoDescuento = async () => {
        try {
            const url = process.env.REACT_APP_SERVER + '/api/v1/customers/consultarGrupoDescuento';
            const response = await axios.get(url);
            const data = response.data;
            let arrayGrupoDescuentoAux = [{ name: "", id: "" }];
            for (let index = 0; index < data.length; index++) {
                arrayGrupoDescuentoAux.push({
                    name: data[index].descripcion_grupo_descuento,
                    id: data[index].id_descuento
                });
            }
            setArrayGrupoDescuento(arrayGrupoDescuentoAux);
        } catch (error) {
            console.log(error);
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (!form.checkValidity()) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            if (objFormulario.numero_identificacion < 0) {
                alert('El número de identificación no debe ser negativo')
            } else {
                if (objFormulario.numero_identificacion % 1 !== 0) {
                    alert('El número de identificación no debe llevar puntos o comas')
                } else {
                    if (objFormulario.terminos_condiciones === 'No acepto') {
                        alert('Debes aceptar los términos y condiciones')
                    } else {
                        const url = process.env.REACT_APP_SERVER + '/api/v1/customers/register';
                        const response = await axios.post(url, objFormulario);
                        const data = response.data;
                        if (data.result) {
                            setMensaje(data.message);
                            setShow(true);
                        } else {
                            setMensaje(data.message);
                            alert(data.message);
                        }
                    }
                }
            }
        }
        setValidated(true);
    };

    const consultarDepartamentos = async () => {
        try {
            const url = process.env.REACT_APP_SERVER + '/api/v1/customers/departments';
            const response = await axios.get(url);
            const data = response.data;
            let arrayDepartamentoAux = [{ name: "", id: "" }];
            for (let index = 0; index < data.length; index++) {
                arrayDepartamentoAux.push({
                    name: data[index].departamento,
                    id: data[index].id
                });
            }
            setArrayDepartamentos(arrayDepartamentoAux);
        } catch (error) {
            console.log(error);
        }
    }

    const consultarCiudades = async (idDepartamento) => {
        try {
            const url = process.env.REACT_APP_SERVER + '/api/v1/customers/cities/' + idDepartamento;
            const response = await axios.get(url);
            const data = response.data;
            let arrayCiudadesAux = [{ name: "", id: "" }];
            for (let index = 0; index < data.length; index++) {
                arrayCiudadesAux.push({
                    name: data[index].f013_descripcion,
                    id: data[index].f013_id
                });
            }
            setArrayCiudades(arrayCiudadesAux);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        consultarDepartamentos();
        consultarGrupoDescuento();
    }, []);

    const changeFormulario = (e) => {
        let { name, value } = e.target;
        if (name === 'terminos_condiciones') {
            if (objFormulario.terminos_condiciones === 'No acepto') {
                setObjFormulario({
                    ...objFormulario,
                    [name]: value
                });
            } else if (objFormulario.terminos_condiciones === 'Acepto') {
                setObjFormulario({
                    ...objFormulario,
                    [name]: 'No acepto'
                });
            }
        } else {
            setObjFormulario({
                ...objFormulario,
                [name]: value
            });
        }
    }

    const validacionTelefono = (e) => {
        const { value } = e.target;
        let mensaje = '';
        let validacion = true;
        if (value.length === 10) {
            changeFormulario(e);
        } else {
            validacion = false;
            mensaje = 'Debe de contener minimo 10 digitos';
        }
        setMensajeValidacionTelefono(mensaje)
        if (!validacion) {
            document.getElementById("telefono").classList.remove("is-valid");
            document.getElementById("telefono").classList.add("is-invalid");
            document.getElementById("telefono").setCustomValidity("invalid");
        } else {
            document.getElementById("telefono").classList.remove("is-invalid");
            document.getElementById("telefono").classList.add("is-valid");
            document.getElementById("telefono").setCustomValidity("");
        }
    }

    const changeDepartamentos = (e) => {
        consultarCiudades(e.target.value);
        changeFormulario(e);
    }

    const changeGrupoDescuento = (e) => {
        let { name, value } = e.target;
        let arrayValue = value.split("-");
        setObjFormulario({
            ...objFormulario,
            [name]: arrayValue[0],
            tipo_descuento_cliente: arrayValue[1]
        });
    }

    const changeDireccion = (e) => {
        const { value } = e.target;
        if (value === "") {
            changeFormulario(e);
        } else {
            let regex = new RegExp("^[a-zA-Z0-9 ]+$");
            if (regex.test(value)) {
                changeFormulario(e);
            }
        }
    }

    return (
        <>
            <Container fluid="md">
                <Row>
                    <Col sm={12}>
                        <Link to='/'>
                            <Figure.Image
                                className='image-logo'
                                alt="171x180"
                                src={imagenLogo}
                            />
                        </Link>
                    </Col>
                </Row>
                <Row style={{ alignItems: 'center' }}>
                    <Col sm={12}>
                        <Row className="m-3 p-3">
                            <Row>
                                <Col sm={12}>
                                    <h4>Formulario de Registro Cliente Detal</h4>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={7} className="m-auto">
                                    <br></br>
                                    <br></br>
                                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                        <Form.Group as={Row} className="mb-3">
                                            <Form.Label column sm={4}>
                                                Número Documento de Identificación
                                            </Form.Label>
                                            <Col sm={8}>
                                                <Form.Control type="number" name="numero_identificacion" placeholder="Número Documento de Identificación" required onChange={changeFormulario} autoComplete="off" />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3">
                                            <Form.Label column sm={4}>
                                                Nombres o Razón Social
                                            </Form.Label>
                                            <Col sm={8}>
                                                <Form.Control type="text" name="nombre" placeholder="Nombres" required onChange={changeFormulario} autoComplete="off" />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3">
                                            <Form.Label column sm={4}>
                                                Primer Apellido
                                            </Form.Label>
                                            <Col sm={8}>
                                                <Form.Control type="text" name="apellido_1" placeholder="Primer Apellido" required onChange={changeFormulario} autoComplete="off" />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3">
                                            <Form.Label column sm={4}>
                                                Segundo Apellido
                                            </Form.Label>
                                            <Col sm={8}>
                                                <Form.Control type="text" name="apellido_2" placeholder="Segundo Apellido" autoComplete="off" />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3">
                                            <Form.Label column sm={4}>
                                                Departamento
                                            </Form.Label>
                                            <Col sm={8}>
                                                <select
                                                    name="departamento"
                                                    className="form-control"
                                                    required
                                                    onChange={changeDepartamentos}
                                                >
                                                    {arrayDepartamentos.map((dpto) => {
                                                        return (
                                                            <option key={dpto.id} value={dpto.id}>
                                                                {dpto.name}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3">
                                            <Form.Label column sm={4}>
                                                Ciudad
                                            </Form.Label>
                                            <Col sm={8}>
                                                <select
                                                    name="ciudad"
                                                    className="form-control"
                                                    required
                                                    onChange={changeFormulario}
                                                >
                                                    {arrayCiudades.map((dpto) => {
                                                        return (
                                                            <option key={dpto.id} value={dpto.id}>
                                                                {dpto.name}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3">
                                            <Form.Label column sm={4}>
                                                Barrio
                                            </Form.Label>
                                            <Col sm={8}>
                                                <Form.Control type="text" name="barrio" placeholder="Barrio" required onChange={changeFormulario} autoComplete="off" />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3">
                                            <Form.Label column sm={4}>
                                                Dirección
                                            </Form.Label>
                                            <Col sm={8}>
                                                <Form.Control type="text" name="direccion" placeholder="Dirección" required value={objFormulario.direccion} onChange={changeDireccion} pattern="^[a-zA-Z0-9 ]+$" autoComplete="off" />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3">
                                            <Form.Label column sm={4}>
                                                Teléfono
                                            </Form.Label>
                                            <Col sm={8}>
                                                <Form.Control type="number" name="telefono" id="telefono" placeholder="Teléfono" required onChange={validacionTelefono} autoComplete="off" />
                                                {mensajeValidacionTelefono !== "" && <p className="text-danger">{mensajeValidacionTelefono}</p>}
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3">
                                            <Form.Label column sm={4}>
                                                Correo Electrónico
                                            </Form.Label>
                                            <Col sm={8}>
                                                <Form.Control type="email" name="email" placeholder="Correo Electrónico" required onChange={changeFormulario} autoComplete="off" />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3">
                                            <Form.Label column sm={4}>
                                                Grupo Descuento
                                            </Form.Label>
                                            <Col sm={8}>
                                                <select
                                                    name="grupo_descuento"
                                                    className="form-control"
                                                    required
                                                    onChange={changeGrupoDescuento}
                                                >
                                                    {arrayGrupoDescuento.map((grupoDescuento) => {
                                                        return (
                                                            <option key={grupoDescuento.name} value={grupoDescuento.id + '-' + grupoDescuento.name} >
                                                                {grupoDescuento.name}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3">
                                            <Col sm={1}>
                                                <Form.Check onChange={changeFormulario}
                                                    name="terminos_condiciones"
                                                    value="Acepto"
                                                    className="mb-3"
                                                    type="checkbox"
                                                    id={`default-checkbox`}
                                                />
                                            </Col>
                                            <Form.Label column sm={8}>
                                                <a target={'_new'} href={'./terminosCondiciones'}>Términos y condiciones</a>
                                            </Form.Label>
                                        </Form.Group>
                                        <div className="d-grid gap-2">
                                            <Button variant="outline-warning" type="submit">Ingresar</Button>
                                        </div>
                                    </Form>
                                </Col>
                            </Row>
                        </Row>
                    </Col>
                </Row>
                <Modal
                    show={show}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{mensaje}</Modal.Title>
                    </Modal.Header>
                    <Modal.Footer>
                        <Link to={'/inicioSesion'} className="btn btn-outline-warning">Aceptar</Link>
                    </Modal.Footer>
                </Modal>
            </Container>
            <Footer />
        </>
    );
}

export { FormularioRegistroUsuarioDetal };