import React, {useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Footer } from '../components/Footer';
import {Container, Row, Col, Form, Button, InputGroup, Modal, Figure} from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import Cookie from 'universal-cookie';


function InicioSesion () {
    const location = useLocation();
    const [mensaje, setMensaje] = useState();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    const [validated, setValidated] = useState(false);
    const [objFormulario, setObjFormulario] = useState({
        correo:"",
        password_cliente:""
    });
    const [imagenBanner, setImagenBanner] = useState([]);

    let imagenLogo = process.env.REACT_APP_SERVER_IMAGE+'estaticas/logo.webp';
    let imagen = process.env.REACT_APP_SERVER_IMAGE+'estaticas/'; 
    let navigate = useNavigate();
    
    const changeFormulario = (e) => {
        let {name, value} = e.target;
        setObjFormulario({
            ...objFormulario,
            [name]:value
        });
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }else{
            try {
                let cookies = new Cookie();
                cookies.remove('textoBuscar');
                if(location.state === null){
                    const url = process.env.REACT_APP_SERVER + '/api/v1/customers/login';
                    const { data } = await axios.post(url, objFormulario);
                    const cookies = new Cookie();
                    cookies.set('accesToken', data.accesToken, {path: '/'});
                    cookies.set('user', data.result[0], {path: '/'})
                    setMensaje(data["message"]);
                    setShow(true);
                    navigate('/catalogo');
                }else{
                    const url = process.env.REACT_APP_SERVER + '/api/v1/customers/login';
                    const { data } = await axios.post(url, objFormulario);
                    const cookies = new Cookie();
                    cookies.set('accesToken', data.accesToken, {path: '/'});
                    cookies.set('user', data.result[0], {path: '/'})
                    setMensaje(data["message"]);
                    setShow(true);
                    navigate(`/productSingle/${location.state.item_pedido}`, {state: {item_pedido: location.state.item_pedido}});
                }
            } catch (err) {
                if(err.response.status === 404){
                    setMensaje(err.response.data.message);
                    setShow(true);
                }
            }
        }

        setValidated(true);
    };

    function mostrarPassword(){
		var campo = document.getElementById("password_cliente");
		if(campo.type === "password"){
			campo.type = "text";
            document.getElementById("iconoOcultar").style="display:none";
            document.getElementById("iconoMostrar").style="display:block";
		}else{
			campo.type = "password";
            document.getElementById("iconoOcultar").style="display:block";
            document.getElementById("iconoMostrar").style="display:none";
		}
	}
    useEffect(() => {
        const bannerIncicio = async() => {
            const cookies = new Cookie();
            const accessToken = cookies.get('accesToken');

            const urlBanner = process.env.REACT_APP_SERVER + '/api/v1/products/banner';
            const dataBanner = await axios.post(urlBanner, {pagina: 'inicioSesion', cliente: 'B2C'}, {
                headers: {
                    'Authorization':`Bearer ${accessToken}`
                }
            });
            const banner = dataBanner.data;  
            setImagenBanner(banner)
            
        }
        bannerIncicio();
    },[]);
   

    return (
        <>
        <Container fluid="md">
            <Row>
                <Col sm={12}>
                    <Link to='/'>
                        <Figure.Image
                            className='image-logo'
                            alt="171x180"
                            src={imagenLogo}
                        />
                    </Link>
                </Col>
            </Row>
            <Row style={{alignItems: 'center'}}>
                <Col sm={7}>
                    <Row className="m-3 p-3 border border-dark rounded mb-0">
                        <Row>
                            <Col sm={12}>
                                <h4>Iniciar Sesión</h4>
                            </Col>
                        </Row>

                        <Row>
                            <Col sm={10} className="m-auto">
                                <label>Acceso Usuarios Registrados</label>
                                <br></br>
                                <label>Por favor ingresar los datos de acceso</label>
                                <br></br>
                                <br></br>

                                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text id="inputGroup-sizing-sm">
                                            <Icon.EnvelopeFill/> 
                                        </InputGroup.Text>
                                        <Form.Control type="email" name="email" className='iniciarSession' placeholder="Ingresar correo electrónico" required onChange={changeFormulario}/>
                                    </InputGroup>

                                    <InputGroup className="mb-3">
                                        <InputGroup.Text id="inputGroup-sizing-sm">
                                        <Icon.LockFill/> 
                                        </InputGroup.Text>
                                        <Form.Control type="password" name="password_cliente" id="password_cliente" className='iniciarSession' placeholder="Ingresar la contraseña" required onChange={changeFormulario} autoComplete="off"/>
                                        <Button variant="outline-warning" className='vista' onClick={mostrarPassword}>
                                            <Icon.Eye id="iconoMostrar" style={{display:"none"}}/>
                                            <Icon.EyeSlash id="iconoOcultar"/>
                                        </Button>
                                    </InputGroup>
                                    <div className="d-grid gap-2">
                                   
                                        <Button variant="outline-warning" type="submit" className='btnIngresar'>Ingresar</Button>
                                        <Link to='/restablecer' className="btn btn-outline-secondary">Recuperar Contraseña</Link>
                                        <center><Link to='/registrarUsuario' className="enlaceRegistro" state={{page: '/'}}><strong>REGISTRARSE AHORA</strong></Link></center>
                                    </div>
                                </Form>
                            </Col>
                        </Row>
                    </Row>
                </Col>

                <Col sm={5} className="m-auto m-3 p-3 ">
                    {
                        imagenBanner.map((fila, id) =>
                            <div key={id} style={{textAlign: 'center'}}>
                                <img src={imagen + fila.pagina} style={{width:"320px", height:"450px"}} alt="product" className="img-fluid" />
                            </div>
                        )
                    }
                </Col>
            </Row>

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{mensaje}</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>

        </Container>
        <Footer />
        </>
    );
}

export {InicioSesion};