import React, { useState, useEffect} from 'react';
import '../sources/styles/stylesCardProductPrice.css';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Cookie from 'universal-cookie';
import {Card, Button, Row, Col, Container, Modal, Form, Table, Alert} from 'react-bootstrap';
import Cookies from 'universal-cookie';
import ReactDOM from 'react-dom/client';
import * as Icon from 'react-bootstrap-icons';

function CardProductoPrice (props) {
    let cookies = new Cookie();
    
    const { propiedades } = props;

    const [precios, setPrecios] = useState([]);
    const [preciosAntes, setPreciosAntes] = useState([]);
    const [descuentos, setDescuentos] = useState([]);
    const [unidadMedida, setUnidadMedida] = useState([]);
    const [validacionDescuento, setValidacionDescuento] = useState('divDescuento ocultar');
    const [validacionPrecio, setValidacionPrecio] = useState('');
    const [validacionAgotado, setValidacionAgotado] = useState('');
    const [validacionStock, setValidacionStock] = useState(false);
    const [stock, setStock] = useState('');
    const [umStock, setUmStock] = useState('');
    const [tipoCliente, setTipoCliente] = useState('');
    const [show, setShow] = useState(false);
    const [showModalResult, setShowModalResult] = useState(false);
    const [tituloMensaje, setTituloMensaje] = useState(null);
    const [validacionAgregarCarrito, setValidacionAgregarCarrito] = useState(false);
    let [objItem, setObjItem] = useState(null);
    const [cantidad, setCantidad] = useState(1);
    const [mensajeResult, setMensajeResult] = useState('');
    const [itemEspecial, setItemEspecial] = useState('NO');
    const [showModalDisponibilidad, setShowModalDisponibilidad] = useState(false);
    const [item, setItem] = useState([]);
    const arrayCentro = {
        '001':'Buga',
        '002':'Palmira',
        '003':'Tuluá'
    };
    
    const formatoPesosColombianos = new Intl.NumberFormat('es-CO', {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 0
    });

    const formatoPorcentaje = (num) =>{
        return Number(num/100).toLocaleString(undefined,{style: 'percent', minimumFractionDigits:2});
    }

    let imagen = process.env.REACT_APP_SERVER_IMAGE + 'items/' + propiedades.item_pedido + '_0_240x240.webp'

    const changeCantidad = (e) => {
        let {value} = e.target;
        setCantidad(value);
    }

    const validacionAgregarProducto = async () => {
        try {
            let token = cookies.get('accesToken');
            let objItemAux = '';
            const url = process.env.REACT_APP_SERVER + '/requireAuth/routes';
            const response = await axios.get(url, {headers: {
                "Authorization": `Bearer ${token}`
            }});
            const data = response.data;
            const urlCliente = process.env.REACT_APP_SERVER + '/api/v1/validarListaPrecio';
            const respCliente = await axios.get(urlCliente, {headers: {
                "Authorization": `Bearer ${token}`
            }});
            
            if(data.validation === 1){
                if(respCliente.data.lista_de_precios === '014'){

                    objItemAux  = {
                        item:propiedades.item_pedido,
                        descripcion:propiedades.descripcion_item,
                        um:unidadMedida,
                        precio:preciosAntes,
                        descuento_original: descuentos,
                        descuento:descuentos,
                        valor_final:precios
                    };
                }else{
                    
                    objItemAux = {
                    item:propiedades.item_pedido,
                    descripcion:propiedades.descripcion_item,
                    um:unidadMedida,
                    precio:preciosAntes,
                    descuento_original: descuentos,
                    descuento:descuentos,
                    valor_final:precios
                };
            }
                setCantidad(1);
                setTituloMensaje("AGREGAR PRODUCTO");
                setObjItem(objItemAux);
                setValidacionAgregarCarrito(true);
            }
        }catch (err) {
            console.log(err);
            if(err.response.status === 403 ||
                err.response.status === 401){
                    setTituloMensaje("Información");
            }
        }
        setShow(true);
    }

    const registrarProductoCarrito = async () => {
        let token = cookies.get('accesToken');
        let user = cookies.get('user');
        if(cantidad % 1 !== 0){
            alert('La cantidad debe ser un número entero')
        }else if(cantidad <= 0){
            alert('La cantidad debe ser mayor a 0')
        }else{
            try {
                let respuesta = true;
                if(user.lista_de_precios === "001"){
                    const urlCargue = process.env.REACT_APP_SERVER + '/api/v1/customers/consultCargue';
                    const responseCargue = await axios.post(urlCargue, { id_cliente: user.id_cliente, item: propiedades.item_pedido, cantidad: cantidad });
                    let dataCargue = responseCargue.data;
                    if(dataCargue.respuesta !== ''){
                        respuesta = window.confirm(dataCargue.respuesta);
                    } 
                }
    
                if(respuesta){
                const urlDescuentosVolumen = process.env.REACT_APP_SERVER + '/api/v1/discountsVolume'
                const bodyDescuentosVolumen = {item: propiedades.item_pedido, cantidad: cantidad, descuento: descuentos}
                const { data } = await axios.post(urlDescuentosVolumen, bodyDescuentosVolumen, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
    
                objItem = {
                    ...objItem,
                    descuento: data.descuentoFinal
                }
    
                const urlRegistrar = process.env.REACT_APP_SERVER + '/api/v1/shoppingCart/register';
                const body = {cantidad, objItem};
                const responseRegistrar = await axios.post(urlRegistrar, body, {headers: {
                    "Authorization": `Bearer ${token}`
                }});
                const dataRegistrar = responseRegistrar.data;
                setMensajeResult(dataRegistrar["message"]);
                setShow(false);
                setShowModalResult(true); 
                cookies.set('accesToken', dataRegistrar["accessToken"], {path: '/'});
                
                const urlCarrito = process.env.REACT_APP_SERVER + '/api/v1/shoppingCart/list';
                const responseCarrito = await axios.get(urlCarrito, {headers: {
                    "Authorization": `Bearer ${token}`
                }});
                const dataCarrito = responseCarrito.data[0];        
                //console.log(dataCarrito)         
                cookies.set('carrito', (dataCarrito.length)); 

                if(user.tipo_usuario === 'temporal'){  
                    const root = ReactDOM.createRoot(
                        document.getElementById('numberCarTemporal')
                    );
                    const element = <span  className="badge badge-dark">{cookies.get('carrito')}</span>
                        root.render(element);
                    }else{
                    const root = ReactDOM.createRoot(
                        document.getElementById('numberCar')
                    );
                    const element = <span  className="badge badge-dark">{cookies.get('carrito')}</span>
                        root.render(element);
                }
            }

            }catch (err) {
                console.log(err);
                if(err.response.status === 403 ||
                    err.response.status === 401){
                        setTituloMensaje("Información");
                }
            }
        }
    }

    const cerrarModal = () =>{
        setShowModalResult(false);
    }

    const handleCloseModal = () => {
        setShow(false)
    }

    const validationRoute = async () => {
        
        const cookies = new Cookies();
        const accessToken = cookies.get('accesToken');
        try {
            const url = process.env.REACT_APP_SERVER + "/requireAuth/routes";
            await axios.get(url, { 
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });
            
        } catch (error) {
            console.log('La validación presentó error')
            console.log(error);                
            cookies.remove('accessToken', {path :"/"});
            cookies.remove('username', {path :"/"});
            cookies.remove('id', {path :"/"});
            window.location.href = "/inicioSesion";
        }
    }
    const abrirModalDisponibilidad = async () =>{
        try {
            const url = process.env.REACT_APP_SERVER + '/api/v1/items/ItemExistencias';
            const response = await axios.post(url, { item: propiedades.item_pedido });
            let data = response.data;
            setItem(data);
        } catch (error) {
            console.log('Error en la consulta de items: ' + error.message);
        }
        setShowModalDisponibilidad(true);
    }

    const cerrarModalDisponibilidad = () =>{
        setShowModalDisponibilidad(false);
    }


    useEffect(() => {
        const cookies = new Cookie();
        const accessToken = cookies.get('accesToken');

        const queryPrecios = async () => {
            setValidacionPrecio('rowPrecio precio1');
            setValidacionDescuento('divDescuento ocultar')

            const urlCliente = process.env.REACT_APP_SERVER + '/api/v1/validarListaPrecio';
            const respCliente = await axios.get(urlCliente, {headers: {
                "Authorization": `Bearer ${accessToken}`
            }});

            const url = process.env.REACT_APP_SERVER + '/api/v1/discount/pricesDefault';
            const body = {item_pedido: propiedades.item_pedido}
            const { data } = await axios.post(url, body, { 
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });

            const urlAgotado = process.env.REACT_APP_SERVER + `/api/v1/productExhausted/${propiedades.item_pedido}`;        
            const dataAgotado = await axios.get(urlAgotado, { 
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });
            if(dataAgotado.data.length > 0){
                let stock = dataAgotado.data[0].stock;
                let um = dataAgotado.data[0].um_inventario;
                setStock(stock);
                setUmStock(um);
                if(stock === 0){               
                    setValidacionAgotado('textAgotado')
                    setValidacionStock(true)
                }else{
                    if(data[0].precio_despues === '0.00'){
                        setValidacionStock(true)

                    }else{

                        setValidacionAgotado('textStock')
                        setValidacionStock(false)
                    }
                }
            }

            setTipoCliente(respCliente.data.tipo_cliente_pagina);

            if(respCliente.data.lista_de_precios === '014'){
                if(propiedades.especial === '1'){
                    setItemEspecial('SI')
                }else{
                    setItemEspecial('NO')
                }
                
                setUnidadMedida(data[0].um);
                setPreciosAntes(parseInt(data[0].precio_antes));
                setPrecios(parseInt(data[0].precio_despues));
                setDescuentos(data[0].descuento);
                setValidacionPrecio('');

                if(parseFloat(data[0].descuento) > 0){
                    setValidacionDescuento('divDescuento');
                }

            }else{
                if(respCliente.data.lista_de_precios  !== '001' && respCliente.data.lista_de_precios !== '010' && respCliente.data.lista_de_precios !== '014' ){
                    
                    setUnidadMedida(data[0].um);
                    setPreciosAntes(parseInt(data[0].precio_antes));
                    setPrecios(parseInt(data[0].precio_despues));
                    setDescuentos(data[0].descuento);
                    setValidacionPrecio('');
                    if(parseFloat(data[0].descuento) > 0){
                        setValidacionDescuento('divDescuento');
                    }
                }else{

                    const urlDesc = process.env.REACT_APP_SERVER + '/api/v1/discounts';
                    const urlDescPersonalizado = process.env.REACT_APP_SERVER + '/api/v1/discountsVolumePersonalize';
                    const bodyDesc = {item_pedido: propiedades.item_pedido};
                    const responseDesc = await axios.post(urlDesc, bodyDesc, {
                        headers: {
                            'Authorization': `Bearer ${accessToken}`
                        }
                    });
                    
                    const bodyDescuentosPersonalize = {item: propiedades.item_pedido, cantidad: 1, descuento:responseDesc.data[0].descuentoFinal * 100};
                    const responseeDescuentoPersonalize = await axios.post(urlDescPersonalizado, bodyDescuentosPersonalize, {
                        headers: {
                            'Authorization': `Bearer ${accessToken}`
                        }
                    });
                        
                    let preciosFinal = data[0].precio * (1 - parseFloat(responseeDescuentoPersonalize.data.descuentoFinal) / 100)
                    preciosFinal = Math.round(preciosFinal);
                
                    setUnidadMedida(data[0].unidad_de_medida);
                    setPreciosAntes(parseInt(data[0].precio));
                    setPrecios(preciosFinal);
                    setDescuentos(responseeDescuentoPersonalize.data.descuentoFinal);
                    setValidacionPrecio('');
                    if(parseFloat(responseeDescuentoPersonalize.data.descuentoFinal) > 0){
                        setValidacionDescuento('divDescuento');
                    }
                }
            }
        }
        queryPrecios();
    }, [propiedades]);
    validationRoute();
    return(
        <Container>
        <Card className="mx-auto cardProducto" style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)', borderRadius: '5px' }} >
            <div className="divInfoAdicionalCard">
                <div className={validacionDescuento} >{formatoPorcentaje(descuentos)}</div>
            </div>
            <Link to={`/productSingle/${propiedades.item_pedido}`} state={{item_pedido:propiedades.item_pedido}}>
                <Card.Img loading='lazy' fallback={<p>Cargando</p>} variant="top" src={imagen} className="imgProducto" id='imgProducto' />
            </Link>
            <Card.Body id="card">
                <Card.Title style={{ lineHeight: '1', textTransform: 'uppercase' }}>
                    <Link to={`/productSingle/${propiedades.item_pedido}`} state={{item_pedido: propiedades.item_pedido}} id='cardTitle'>{propiedades.descripcion_item}</Link>
                </Card.Title>
                <Card.Text id='cardProducto'>
                    <small>Ref: {propiedades.referencia}</small>
                    <br></br>
                    <small>Codigo: {propiedades.item_pedido}</small>
                    
                </Card.Text>
                {
                    stock === 0
                    ?
                    <p className={validacionAgotado}>VALIDAR DISPONIBILIDAD</p>
                    :
                    <p className={validacionAgotado}>Disponibilidad: {stock} {umStock} <Button variant="outline-secondary" size="sm" onClick={abrirModalDisponibilidad}><Icon.InfoCircleFill/></Button></p>

                }
                    {   
                        preciosAntes === precios
                        ?
                        <Row className={validacionPrecio} style={{height: "50px"}}>
                            <Col md={12} lg={6} className="catalogoVertical">
                                <Row>
                                    <Col xs={12}>
                                        <b className="colorTexto precio">{formatoPesosColombianos.format(precios)}</b>
                                    </Col>
                                </Row>
                            </Col>
                            <Col sm={12} className={'divExclusivo'}>
                                <small >   </small>
                            </Col>
                        </Row>
                        :
                        <Row className={validacionPrecio} style={{height: "50px"}}>
                            <Col md={12} lg={6} className="catalogoVertical">
                                <small className="textoTachado">Antes: {formatoPesosColombianos.format(preciosAntes)}</small>
                            </Col>
                            <Col md={12} lg={6} style={{display: "contents"}}>
                                <Row>
                                    <Col xs={12}>
                                        <b className="colorTexto">AHORA</b>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <b className="colorTexto">{formatoPesosColombianos.format(precios)}</b>
                                    </Col>
                                </Row>
                            </Col>
                            {
                                (propiedades.precio_solo_pagina === tipoCliente)||(propiedades.precio_solo_pagina === 'TODOS') 
                                ?
                                <Col xs={12} style={{color:"green"}} className={'divExclusivo'}>
                                    <Icon.Globe/><strong><small > Precio solo página</small></strong>
                                </Col>
                                
                                :
                                <Col xs={12} className={'divExclusivo'}>
                                    <small></small>
                                </Col>
                            }
                        </Row>
                    }
                    {
                        propiedades.envio_gratis === 'SI'
                        ?
                        <Row style={{height: "25px"}}>
                            <Col >
                                <Row>
                                    <Col xs={12} className='envioGratis'>
                                        
                                    <Card.Text><Icon.Truck/><strong><small> Envío Gratis</small></strong></Card.Text>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        
                        :
                        <Row style={{height: "25px"}}>
                            <Col md={12} lg={6}>
                                <Row>
                                    <Col xs={12} className='envioGratis'>
                                        <Card.Text><small>   </small></Card.Text>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    }
                    <Card.Text className='textIvaIncluido'><small>Con iva incluido. COP</small></Card.Text>
                    {
                        itemEspecial === 'NO'
                        ?
                        <Button id='btnCatalogo' variant="outline-warning" style={{width: '100%'}} className={validacionPrecio} disabled={validacionStock} onClick={validacionAgregarProducto}>
                            <Icon.CartCheckFill/>   Agregar al carrito
                        </Button>
                        :
                        <Button variant="outline-warning" style={{width: '100%'}} className={validacionPrecio} target={"_new"} href={`https://wa.me/+573162078505?text=Hola, me encuentro en la página web de la FERRETERIA MASTER y quisiera conocer más información sobre el producto ${propiedades.descripcion_item}, ítem ${propiedades.item_pedido} con referencia ${propiedades.referencia}` } >
                            <Icon.Whatsapp/>   Asesor
                        </Button>
                    }
            </Card.Body>
        </Card>

        <Modal
            show={show}
            backdrop="static"
            keyboard={false}
            onHide={handleCloseModal}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {tituloMensaje}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    validacionAgregarCarrito
                    ?
                        <Row>
                            <Col sm={12}>
                                Ingrese la cantidad que desea comprar de este producto
                                <br></br>
                                <b>Item:</b> {objItem.descripcion}
                                <br></br>
                                <b>Código:</b> {objItem.item}
                                <br></br>
                                <b>UM:</b> {objItem.um}
                                <br></br>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm={4}>
                                        <b>Cantidad:</b>
                                    </Form.Label>
                                    <Col sm={8}>
                                        <Form.Control type="number" name="cantidad"  placeholder="1" min="0" value={cantidad} onChange={changeCantidad}/>
                                    </Col>
                                </Form.Group>
                                <div className="d-grid gap-2">
                                    <Button variant="outline-warning btnAgregar" type="submit" onClick={registrarProductoCarrito}>Agregar al carrito</Button>
                                    <Button variant="outline-secondary" type="button" onClick={() => handleCloseModal()}>Cancelar</Button>
                                </div>
                            </Col>
                        </Row>
                    :
                        <Row>
                            <Col sm={12}>
                                Para agregar productos al carrito de compra se debe de iniciar sesión
                                <br></br>
                                <Link to='/inicioSesion' className='btn btn-outline-warning'>Iniciar sesión</Link>
                            </Col>
                        </Row> 
                }
            </Modal.Body>
        </Modal>
        <Modal
            show={showModalResult}
            backdrop="static"
            keyboard={false}
            onHide={cerrarModal}
        >
            <Modal.Header closeButton>
                <Modal.Title>Información</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {mensajeResult}
                <div className="d-grid gap-2">
                    <Button variant="outline-warning btnAgregar" type="submit" onClick={cerrarModal}>Continuar Comprando</Button>
                    <Link to='/carrito' className='btn btn-outline-secondary'>Ir al Carrito</Link>
                </div>
            </Modal.Body>
        </Modal>

        <Modal
            show={showModalDisponibilidad}
            keyboard={false}
            onHide={cerrarModalDisponibilidad}
        >
            <Modal.Header closeButton>
                <Modal.Title>Información</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    item.length > 0
                        ?
                        <Container>
                            <b>Item:</b> {item[0].descripcion_item}
                            <br></br>
                            <b>Código:</b> {item[0].item}
                            <br></br>
                            <b>UM:</b> {item[0].um_inventario}
                            <br></br>
                            <Row>
                                <Col sm={12}>
                                    <Table size="sm">
                                        <thead>
                                            <tr>
                                                <th>Instalación</th>
                                                <th>Disponible</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                item.map((item, i) =>
                                                    <tr key={i}>
                                                        <td>{arrayCentro[item.co]}</td>
                                                        <td>{item.disponibilidad}</td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </Container>
                        :
                        <Alert style={{ width: '400px' }} className="mx-auto text-center">
                            <b>Sin Resultados</b>
                        </Alert>
                }
            </Modal.Body>
        </Modal>
        </Container>
    )
}

export {CardProductoPrice};