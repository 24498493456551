import React, { useEffect, useState } from 'react';
import { Header } from '../components/Header';
import { Footer } from '../components/Footer';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import * as Icon from 'react-bootstrap-icons';
import axios from 'axios';

function ResponseEmail() {
    const [mensaje, setMensaje] = useState();
    useEffect(() => {
        const queryString = decodeURIComponent(window.location.search);
        const searchParams = new URLSearchParams(queryString);
        const id = searchParams.get("id");
        const email = searchParams.get("email");
        const validarEmail = async () => {
            const url = process.env.REACT_APP_SERVER + '/api/v1/customers/validarEmailCancelar';
            const response = await axios.post(url, { id: id, email: email });
            const data = response.data;
            setMensaje(data.mensaje);
        }
        validarEmail();
    }, []);

    return (
        <>
            <Header />
            <Container>
                <Row >
                    <Col style={{ textAlign: 'center' }}>
                        <h2>{mensaje}</h2>
                    </Col>
                </Row>

                <Row className="m-3 p-3 mb-0">
                    <Col sm={12}>
                        <Card>
                            <Card.Header>Sede Buga</Card.Header>
                            <Card.Body>
                                <blockquote className="blockquote mb-0">
                                    <small>
                                        <Icon.GeoAlt />
                                        Dirección: CALLE 11 7 34
                                        <br></br>
                                        <Icon.Telephone />
                                        Teléfono: 2363000
                                    </small>
                                </blockquote>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <Row className="m-3 p-3 mb-0">
                    <Col sm={12}>
                        <Card>
                            <Card.Header>Sede Palmira</Card.Header>
                            <Card.Body>
                                <blockquote className="blockquote mb-0">
                                    <small>
                                        <Icon.GeoAlt />
                                        Dirección: CARRERA 33A 28 24
                                        <br></br>
                                        <Icon.Telephone />
                                        Teléfono: 2660066
                                    </small>
                                </blockquote>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <Row className="m-3 p-3 mb-0">
                    <Col sm={12}>
                        <Card>
                            <Card.Header>Sede Tuluá</Card.Header>
                            <Card.Body>
                                <blockquote className="blockquote mb-0">
                                    <small>
                                        <Icon.GeoAlt />
                                        Dirección: CARRERA 40 33 21
                                        <br></br>
                                        <Icon.Telephone />
                                        Teléfono:2339733
                                    </small>
                                </blockquote>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} style={{ textAlign: 'center' }}>
                        <Link to="/catalogo" className="btn btn-warning" >Ver Catalogo</Link>
                    </Col>
                </Row>
            </Container>
            <Footer />
        </>
    );
}

export { ResponseEmail };