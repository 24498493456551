import React, { useEffect, useState, Fragment } from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie';
import moment from 'moment';
import * as Icon from 'react-bootstrap-icons';
import { Header } from '../components/Header';
import { Container, Row, Col, Form, Table, Button, Modal, Alert } from "react-bootstrap";
import Spinner from 'react-bootstrap/Spinner';

function Cotizacion() {

    const [cotizaciones, setCotizaciones] = useState([]);
    const [itemsCotizacion, setItemsCotizacion] = useState([]);
    const [fechas, setFechas] = useState({ fecha_inicial: moment().format('YYYY-MM-DD'), fecha_final: moment().format('YYYY-MM-DD') });
    const [lgShow, setLgShow] = useState(false);
    const [cargaShow, setCargaShow] = useState(false);
    const [visualizacion, setVisualizacion] = useState(false);

    const formatoPesosColombianos = new Intl.NumberFormat('es-CO', {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 0
    });

    const formatoPorcentaje = (num) => {
        return Number(num / 100).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2 });
    }

    const handleFechas = e => {
        const { name, value } = e.target
        if (name === 'fecha_inicial') {
            if (moment(value) > moment(fechas.fecha_final)) {
                alert('La fechas inicial no puede ser mayor a la fecha final')
            } else {
                setFechas({
                    ...fechas,
                    [name]: value
                });
            }
        } else {
            if (moment(value) < moment(fechas.fecha_inicial)) {
                alert('La fecha final no puede ser menor a la fecha inicial')
            } else {
                setFechas({
                    ...fechas,
                    [name]: value
                });
            }
        }
    }

    const handleModalItems = async (id_cotizacion) => {
        const cookies = new Cookies();
        const accessToken = cookies.get('accesToken');
        setLgShow(true);
        try {
            const url = process.env.REACT_APP_SERVER + '/api/v1/cotizacion/items';
            const body = { id_cotizacion };
            const { data } = await axios.post(url, body, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });
            setItemsCotizacion(data);
        } catch (error) {
            console.log('Error en la consulta de items pedidos: ' + error.message)
        }
    }
    const handleEditar = async (id_cotizacion) => {
        const cookies = new Cookies();
        const accessToken = cookies.get('accesToken');
        setVisualizacion(false)
        setCargaShow(true)
        try {
            const url = process.env.REACT_APP_SERVER + '/api/v1/cotizacion/items';
            const body = { id_cotizacion };
            const { data } = await axios.post(url, body, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });
            let objDatos = [];
            if (data.length > 0) {
                for (let value of data) {
                    objDatos.push({
                        cliente: value.cliente,
                        sucursal: value.sucursal_cliente,
                        punto_envio: value.punto_envio,
                        item: value.item,
                        um: value.um,
                        cantidad: value.cantidad,
                        lista: value.lista,
                        precio: value.precio,
                        descuento_original: value.descuento_original,
                        descuento: value.descuento,
                        valor_final: value.valor_final,
                        fecha_creacion: value.fecha_creacion
                    })
                }
            }
            InsertPedido(objDatos)
        } catch (error) {
            console.log('Error al editar los items del pedido: ' + error.message)
        }
    }
    const handleBorrarCotizacion = async (id_cotizacion) => {
        try {
            const url = process.env.REACT_APP_SERVER + '/api/v1/updateCotizacion';
            const { data } = await axios.post(url, { id_cotizacion: id_cotizacion })
            if (data.result) {
                window.location.reload(true);
            }
        } catch (error) {
            console.log('Error al editar los items del pedido: ' + error.message)
        }
    }

    const InsertPedido = async (datos) => {
        const url = process.env.REACT_APP_SERVER + '/api/v1/insertCar';
        const { data } = await axios.post(url, datos);
        if (data.result) {
            setVisualizacion(true)
        }
    }

    const handleModalItemsCerrar = () => {
        setLgShow(false);
        setItemsCotizacion([]);
    }
    const handleModalCargaCerrar = () => {
        setCargaShow(false);
    }

    const queryPedidos = async (fechas2) => {
        const cookies = new Cookies();
        const accessToken = cookies.get('accesToken')
        try {
            const url = process.env.REACT_APP_SERVER + '/api/v1/pedidos/cotizacion/cliente'
            const { data } = await axios.post(url, fechas2, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });
            setCotizaciones(data);
        } catch (err) {
            console.log("Error en la consulta de estado pedido: " + err)
        }
    }

    const modalItems = <Modal
        size="lg"
        show={lgShow}
        onHide={() => setLgShow(false)}
        className="text-center"
        aria-labelledby="example-modal-sizes-title-lg"
    >
        <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
                Items del Pedido
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Table responsive bordered>
                <thead>
                    <tr>
                        <th>ITEM</th>
                        <th>DESCRIPCIÓN</th>
                        <th>UM</th>
                        <th>CANTIDAD</th>
                        <th>IVA</th>
                        <th>DESCUENTO</th>
                        <th>VALOR FINAL</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        itemsCotizacion.map((item, i) =>
                            <tr key={i}>
                                <td>{item.item}</td>
                                <td>{item.descripcion_item}</td>
                                <td>{item.um}</td>
                                <td>{Math.round(item.cantidad)}</td>
                                <td>{formatoPorcentaje(item.iva)}</td>
                                <td>{formatoPorcentaje(item.descuento)}</td>
                                <td>{formatoPesosColombianos.format(Math.round(item.valor_final))}</td>
                            </tr>
                        )
                    }
                </tbody>
            </Table>
        </Modal.Body>
        <Modal.Footer>
            <Button onClick={() => handleModalItemsCerrar()}>Close</Button>
        </Modal.Footer>
    </Modal>
    const modalCarga = <Modal
        size="lg"
        show={cargaShow}
        /* onHide={() => setCargaShow(false)} */
        className="text-center"
        aria-labelledby="example-modal-sizes-title-lg"
    >
        <Modal.Header >
            <Modal.Title id="example-modal-sizes-title-lg">
                CONVIRTIENDO COTIZACIÓN
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {
                visualizacion === false
                    ?
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                    :
                    <a href='/carrito' target={'_blank'} onClick={() => handleModalCargaCerrar()}>IR</a>
            }
        </Modal.Body>
    </Modal>

    useEffect(() => {
        queryPedidos(fechas);
    }, [fechas])
    return (
        <>
            <Header />
            <br />
            <Container>
                <Row className="mb-4" sm={6}>
                    <Col sm={6}>
                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                            <Form.Label column sm="2">
                                Fecha Inicio
                            </Form.Label>
                            <Col sm="5">
                                <Form.Control max={fechas.fecha_final} name="fecha_inicial" value={fechas.fecha_inicial} type="date" onChange={handleFechas} />
                            </Col>
                        </Form.Group>
                    </Col>
                    <Col sm={6}>
                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                            <Form.Label column sm="2">
                                Fecha Final
                            </Form.Label>
                            <Col sm="5">
                                <Form.Control min={fechas.fecha_inicial} name="fecha_final" value={fechas.fecha_final} type="date" onChange={handleFechas} />
                            </Col>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    {
                        cotizaciones.length > 0 ?
                            <Table responsive bordered className="text-center">
                                <thead>
                                    <tr>
                                        <th>COTIZACION</th>
                                        <th>ITEMS</th>
                                        <th>FECHA</th>
                                        <th>VALOR</th>
                                        <th>COTIZACION</th>
                                        <th>VALIDACION</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        cotizaciones.map((item, i) =>
                                            <tr key={i}>
                                                <td>{item.numero_cotizacion}</td>
                                                <td>
                                                    {
                                                        <Button variant="outline-warning" onClick={() => handleModalItems(item.numero_cotizacion)}>
                                                            <Icon.Basket2 />
                                                        </Button>
                                                    }
                                                    {modalItems}
                                                </td>
                                                <td>{moment(item.fecha_creacion).format('YYYY-MM-DD HH:mm:ss')}</td>
                                                <td>{formatoPesosColombianos.format(Math.round(item.valor_final))}</td>
                                                <td><a href={item.url} target={"_new"}><strong>VER</strong></a></td>
                                                <td>
                                                    {
                                                        <Button variant="outline-success" title='Editar Cotización' onClick={() => handleEditar(item.numero_cotizacion)}>
                                                            <Icon.PencilSquare />
                                                        </Button>
                                                    }
                                                    {modalCarga}
                                                    <Button variant="outline-danger" title='Borrar Cotización' onClick={() => handleBorrarCotizacion(item.numero_cotizacion)}>
                                                        <Icon.XCircle />
                                                    </Button>
                                                </td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </Table>
                            :
                            <Alert style={{ width: '500px' }} className="mx-auto text-center">
                                <b>No tienes cotizaciones. Prueba con otro rango de fechas</b>
                            </Alert>
                    }
                </Row>
            </Container>
        </>
    );
}
export { Cotizacion };