import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie';
import { Header } from '../components/Header';
import { Container, Row, Col, Table, Alert } from 'react-bootstrap';
import { Footer } from '../components/Footer';

function EstadoCartera() {

    const [datos, setDatos] = useState([]);
    const [total, setTotal] = useState(0);

    const formatoPesosColombianos = new Intl.NumberFormat('es-CO', {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 0
    });

    const queryDatos = async () => {
        const cookies = new Cookies();
        const accessToken = cookies.get('accesToken');
        try {
            const url = process.env.REACT_APP_SERVER + '/api/v1/estadoCuenta/datos';
            const { data } = await axios.get(url, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });
            let total = 0;
            for (let value of data) {
                total += parseFloat(value.saldo)
            }
            setTotal(Math.round(total));
            setDatos(data);
        } catch (error) {
            console.log('Error en la consulta de datos: ' + error.message);
        }
    }

    useEffect(() => {
        queryDatos();
    }, []);

    return (
        <>
            <Header />
            <br />
            <Container>
                <Row>
                    <Col>
                        {
                            datos.length > 0
                                ?
                                <di>
                                    <Table borderless className="text-center" border='2' style={{ width: '50%', margin: 'auto' }}>
                                        <thead>
                                            <tr>
                                                <th>CLIENTE:</th>
                                                <th>{datos[0].nombre}</th>
                                            </tr>
                                            <tr>
                                                <th>IDENTIFICACIÓN:</th>
                                                <th>{datos[0].cliente}</th>
                                            </tr>
                                        </thead>
                                    </Table>
                                    <br />
                                    <Table borderless className="text-center" border='2' style={{ width: '50%', margin: 'auto' }}>
                                        <tr>
                                            <th colSpan={5}>TOTAL</th>
                                            <td>{formatoPesosColombianos.format(total)}</td>
                                        </tr>
                                    </Table>
                                    <br />
                                    <Table striped borderless bordered responsive className="text-center">
                                        <thead>
                                            <tr>
                                                <th>FECHA</th>
                                                <th>FECHAV</th>
                                                <th>TIPO</th>
                                                <th>NÚMERO</th>
                                                <th style={{ width: '400px' }}>NOTA</th>
                                                <th>SALDO</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                datos.map((item, i) =>
                                                    <tr key={i}>
                                                        <td>{item.fecha}</td>
                                                        <td>{item.fechaV}</td>
                                                        <td>{item.tipo}</td>
                                                        <td>{item.numero}</td>
                                                        <td style={{ width: '400px' }}>{item.notas}</td>
                                                        <td>{formatoPesosColombianos.format(item.saldo)}</td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </Table>
                                </di>
                                :
                                <Alert style={{ width: '400px' }} className="mx-auto text-center">
                                    <b>No tienes un historial de cartera</b>
                                </Alert>
                        }
                    </Col>
                </Row>
            </Container>
            <Footer />
        </>
    )
}

export { EstadoCartera };