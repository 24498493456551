import React from 'react';
import { Container } from 'react-bootstrap';
import { Footer } from '../components/Footer';
import { Header } from '../components/Header';
import '../sources/styles/stylesTexto.css';

function Devoluciones() {
    const politica = 'http://consultas.ferremaster.com.co:8080/calidad/CONSULTA/SubirArchivo/files/PL-COM-001%20POLITICA%20DE%20DEVOLUCIONES%20v.004.pdf';
    return (
        <>
            <Header />
            <Container fluid="md">
                <h1>POLÍTICA DE DEVOLUCIONES</h1>
                <div className='dateInforme'>
                    <p>
                        SOCIEDAD FERRETERA DE COMERCIO S.A.S. ha elaborado la siguiente política de devoluciones con el
                        objetivo de brindar un mejor servicio a nuestros clientes:<br /><br />
                        <strong>1.</strong> Para hacer efectiva la devolución, el cliente debe traer la factura original que sustente la compra del
                        producto. <strong>NOTA:</strong> en caso de que el cliente no tenga la factura, la ferretería tiene un plazo máximo de (2)
                        días hábiles para la búsqueda o reimpresión de dicho documento; hasta entonces la devolución no podrá
                        ser realizada.<br /><br />
                        <strong>2.</strong> Una devolución de producto tiene un plazo máximo de (2) períodos de IVA, a partir de la fecha de emisión
                        de la factura.<br /><br />
                        <strong>3.</strong> Para poder realizarse la devolución de producto, esta mercancía debe venir en perfectas condiciones que
                        permita una futura venta. Algunos casos puntuales a tener en cuenta son:<br /><br />
                        ✔ No se recibe cerámica que se vendió como saldo o que al recibirla se pueda convertir en saldo.<br />
                        ✔ Las fechas de vencimiento de los productos deben tener una vigencia superior a 4 meses.<br />
                        ✔ Accesorios y repuestos sanitarios, una vez abiertos, no tienen cambio ni devolución.<br />
                        ✔ No se reciben devoluciones de productos a los que se les ha realizado algún tipo de tratamiento (corte
                        o mezcla de pintura).<br />
                        ✔ No se recibe producto como cable eléctrico, alambre, manguera, cadena eslabonada en metros. Solo
                        se reciben rollos completos.<br />
                        ✔ Si la mercancía que se está devolviendo es cerámica, sólo se recibirán lotes que tengan existencia en
                        bodega y que no sea un saldo. Las reclamaciones sobre lote o calidad de cerámica (tamaño, color)
                        deben realizarse ANTES de instalar el producto, puesto que la instalación del material implica su
                        aceptación.<br />
                        ✔ El producto debe estar en condiciones aptas para su venta (sin uso, sin armar, sin daños en el producto
                        y empaque original, catálogos, manuales y todas sus piezas).<br />
                        ✔ No se recibe varilla en estado deteriorado (óxido).<br /><br />
                        <strong>4.</strong> La devolución se establece como equivalente al valor real pagado por el producto. En caso de necesitar
                        un reintegro de dinero por el producto devuelto, el cliente debe presentarse personalmente y traer la cédula;
                        se debe tener en cuenta que si el cliente es una empresa, no se realizará una devolución a un tercero, sino
                        que en este caso se debe traer como documentos adicionales: cámara de comercio actualizada (vigencia
                        de 3 meses), carta de autorización del representante legal donde se solicita la devolución de dinero y la
                        certificación bancaria para realizar el respectivo pago a la cuenta designada.<br /><br />
                        <strong>5.</strong> El cliente puede utilizar el saldo a favor de una devolución para realizar la compra de otros productos de la
                        ferretería.<br /><br />
                        <strong>6.</strong> En caso de las entregas realizadas en obra, el cliente siempre debe exigir el documento “CONTROL DE
                        DEVOLUCIONES DE MERCANCÍA” a la hora de realizar una devolución, ya que este es el único soporte
                        en caso de una futura reclamación. Si por algún motivo el conductor no tiene el respectivo soporte, es
                        obligación del cliente colocar las respectivas observaciones en la factura de la empresa, teniendo en cuenta
                        que no se puede alterar la información que ya se encuentra en este documento valor.<br /><br />
                        <strong>7.</strong> La mercancía en devolución debe ser trasladada a la ferretería por el cliente.<br /><br />
                        <strong>8.</strong> No es válido realizar devoluciones por motivo de cambio de fecha. Al momento que se le notifica al cliente
                        la factura electrónica este tiene 3 días una vez haya recibido la mercancía para aprobar o rechazar el
                        documento. Si pasados los 3 días el cliente no hace ninguna manifestación se da por aprobada
                        tácitamente.<br /><br />
                    </p>
                    <h3 className='negrita'><a id='manual' target={'_new'} href={politica}>POLÍTICA DE DEVOLUCIÓN</a></h3><br />
                </div>
            </Container>
            <Footer />
        </>
    )
}

export { Devoluciones };