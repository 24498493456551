import { Container } from 'react-bootstrap';
import { Footer } from '../components/Footer';
import { Header } from '../components/Header';

import '../sources/styles/stylesTexto.css';

function TerminosCondiciones () {
    return(
        <>
            <Header/>
            <Container fluid="md">                
                <h1>TÉRMINOS Y CONDICIONES</h1>
                <div className='dateInforme'>
                    <p>
                        ​​​​​​​Los clientes tienen la posibilidad de realizar todos sus pedidos desde la página de Internet.<br/><br/>

                        Cada cliente declara que cuenta con plena capacidad para utilizar tarjetas de crédito y que las mismas tienen fondos suficientes para cubrir todos los costos que resultan de la compra de productos a través de www.ferremaster.com.co<br/><br/>

                        Al presionar el botón "Hacer Pedido" durante el proceso de compra, el cliente declara aceptar plenamente y sin reservas la totalidad de las Condiciones Generales de Venta.<br/><br/>

                        Los datos registrados por SOFERCO S.A.S constituyen la prueba de las transacciones hechas entre SOFERCO S.A.S. y los miembros de su Comunidad Privada. SOFERCO S.A.  confirmará su pedido a través del envío de un correo electrónico.<br/><br/>

                        La información contractual se presenta en lengua española y será confirmada en el momento de la entrega.<br/><br/>

                        En los precios de nuestros productos están incluidos todos los impuestos, pero no incluyen los gastos de transporte.<br/><br/>

                        SOFERCO S.A.S. se reserva el derecho a modificar sus precios en cualquier momento, pero los productos se facturarán sobre la base de las tarifas en vigor en el momento del registro de los pedidos (a reserva de la disponibilidad que haya del producto), salvo error tipográfico manifiesto.<br/><br/>

                        Los productos permanecen en propiedad del proveedor hasta el momento en el que se haya producido el pago completo del precio.<br/><br/>

                        SOFERCO S.A.S. se reserva el derecho a rechazar un pedido de cualquier miembro con el que tenga un litigio.
                    </p>
                </div>
                <h2><b>Disponibilidad</b></h2>
                <div className='dateInforme'>
                    <p>
                    SOFERCO S.A.S. hará todo lo posible por complacer a todos sus clientes en la demanda de los productos.<br/><br/>

                    En caso de  no disponibilidad del producto después de haberse realizado el pedido el cliente será informado por correo electrónico de anulación de éste, sin que por tal circunstancia SOFERCO S.A.S. se obligue a responder por perjuicios distintos a la devolución del dinero pagado por el cliente.<br/><br/>

                    A raíz de esta demanda, la rapidez de la devolución en la cuenta bancaria del cliente dependerá del tipo de  cuenta bancaria y de las condiciones de cada entidad financiera. Estos plazos de nuevo se precisan en la cláusula "Entrega".
                    </p>
                </div>
                <h2><b>Seguridad</b></h2>
                <div className='dateInforme'>
                    <p>
                    Para su seguridad SOFEERCO S.A ha confiado en el sistema de pago mediante tarjeta de crédito a una pasarela de pagos.<br/><br/>

                    Los datos bancarios introducidos son encriptados y transmitidos de forma segura a los servidores de la entidad bancaria y, posteriormente, son verificados con el banco emisor para evitar posibles fraudes y abusos.<br/><br/>

                    Este procedimiento de introducción de datos está garantizado por la tecnología de encriptación SSL (Secure Socker Layer) -128 bits, uno de los sistemas de protección más avanzados y eficaces actualmente disponibles, gracias al cual, ningún tercero tendrá acceso vía Internet a esta información relativa a los datos bancarios introducidos por el cliente.<br/><br/>

                    Sólo el Banco y la pasarela de pagos tienen acceso a los datos bancarios vinculados a estos medios de pago, de manera que SOFERCO S.A.S. no conoce ni registra estos datos durante la operación de pago.
                    </p>
                </div>
                <h2><b>Devolución</b></h2>
                <div className='dateInforme'>
                    <p>
                    Todos los productos vendidos podrán ser devueltos durante 7 días contados desde la fecha de entrega, de acuerdo con las disposiciones de la Ley 1480 del 12 de  octubre de 2011.<br/><br/>

                    Previamente, el cliente deberá indicar su intención por correo electrónico. SOFERCO S.A.S. contestará por medio de otro correo electrónico especificando las instrucciones precisas para la devolución del producto.<br/><br/>

                    Los gastos de devolución correrán a cargo del cliente.<br/><br/>

                    El reembolso en la cuenta del cliente como consecuencia de esta devolución, se efectuará en el plazo de 7 días a partir de la recepción a satisfacción de las mercancías por SOFERCO S.A.S..<br/><br/>

                    La devolución no podrá tener lugar en ningún caso si los productos suministrados fueron manifiestamente objeto de uso.<br/><br/>

                    Los productos imperativamente deben devolverse correctamente protegidos, en su embalaje de origen, en un perfecto estado (no dañados o ensuciados por el cliente) con todos los accesorios, instrucciones y documentación. Serán enviados al lugar especificado por SOFERCO S.A.S. en las instrucciones precisas para la devolución del producto enviadas mediante correo electrónico.<br/><br/>

                    En el caso de que la devolución no fuera aceptada por el proveedor por entender que el producto cuya devolución se pretende hubiera sido utilizado, estos productos permanecerán en el almacén de distribución para ser recogidos por el cliente, por un período no superior a treinta días desde su devolución.<br/><br/>

                    No podrán tampoco devolverse los paquetes en los que no conste ningún elemento adjunto que permita identificar al remitente (n° de pedido, nombre, dirección...).<br/><br/>

                    Los gastos y riesgos vinculados a la devolución del producto serán a cargo del remitente, quien deberá enviarlos de forma segura y con las garantías necesarias para que la mercancía devuelta llegue en perfecto estado de conservación.<br/><br/>

                    A través de SOFERCO S.A.S. se pueden realizar devoluciones de los productos comprados por el importe de los mismos. Una vez recibido/s el/los productos devueltos, SOFERCO S.A.S. verificará que estén perfecto estado y procederá al abono del importe pagado menos los gastos de envío liquidados mas el IVA, independientemente del importe del producto devuelto.<br/><br/>

                    Toda persona que devuelva su producto recibirá un correo electrónico informándole del importe de su reembolso.
                    </p>
                </div>
                <div className='dateInforme'>
                <h2><b>Garantía</b></h2>
                    <p>
                    Las garantías y servicios postventa se regirán por la Ley 1480 de octubre 12 de 2011 “Por medio del cual se expide el Estatuto del Consumidor y se dictan otras disposiciones”. De acuerdo con esta normatividad, el vendedor está obligado a entregar al consumidor y usuario productos que sean conformes con el contrato, respondiendo frente al él de cualquier falta de conformidad. El consumidor tiene derecho a la reparación del producto, a su sustitución por uno de iguales o similares características técnicas o a la devolución de lo pagado y a la resolución del contrato.
                    </p>
                </div>
                <h2><b>Responsabilidad, litigios y legislación aplicable</b></h2>
                <div className='dateInforme'>
                    <p>
                    SOFERCO S.A.S. no podría considerarse como responsable del incumplimiento del contrato celebrado en caso de ruptura de existencias o indisponibilidad del producto, fuerza mayor, perturbación o huelga total o parcial, en particular, de los servicios postales y medios de transporte y/o comunicaciones, inundación o incendio. En caso de litigio, el miembro se dirigirá por prioridad a SOFERCO S.A.S. para obtener una solución amistosa.<br/><br/>

                    Los Tribunales de Colombia son los únicos competentes. Se interpretarán las presentes condiciones generales de venta en lengua española y se realizarán de acuerdo con el derecho Colombiano. Todo desacuerdo que pudiera nacer con motivo de su validez, su interpretación o su ejecución se presentará ante la jurisdicción ordinaria de Guadalajara de Buga- Colombia, a los cuales se hace expresamente atribución exclusiva de competencia.
                    </p>
                </div>
                <h2><b>Modificación de las condiciones generales de venta</b></h2>
                <div className='dateInforme'>
                    <p>
                    SOFERCO S.A.S. se reserva el derecho a modificar las condiciones generales de venta sin previo aviso, pudiendo cambiar, suprimir o añadir tanto los contenidos y servicios que se presten a través del mismo como la forma en la que estos parezcan presentados o localizados en sus servidores.<br/><br/>

                    Estos cambios deberán de ser aceptados por el comprador cada vez que haga efectiva una compra a través de la página SOFERCO S.A.S..<br/><br/>

                    Los miembros de la "Comunidad Privada de SOFERCO S.A.S.” que no estén conformes con las modificaciones de las condiciones generales, deberán notificarlo y, a partir de la fecha en la cual la nueva versión entrará en vigor, deberán dejar de utilizar los servicios SOFERCO S.A.S.. En el supuesto de alguno de los términos de las condiciones generales fuera ilegal o inoponible, será nula de pleno derecho y aplicándose lo acordado por la partes y en su defecto lo dispuesto en el Código Civil Colombiano.
                    </p>
                </div>
                <h2><b>Autorización para el tratamiento de datos personales</b></h2>
                <div className='dateInforme'>
                    <p>Al presionar el botón "Hacer Pedido" durante el proceso de compra, el cliente declara aceptar plenamente y sin reservas a la empresa para que realice el tratamiento de sus datos personales, incluyendo compilar, almacenar, consultar, usar, compartir, intercambiar, transmitir, transferir, circular, divulgar mis datos
                    personales durante y con posterioridad a la relación laboral que tengo con la empresa, de conformidad con los términos y condiciones establecidos en la política de tratamiento de datos personales de SOCIEDAD
                    FERRETERA DE COMERCIO S.A.S., con las siguientes finalidades, todas relacionados con las actividades de SOCIEDAD FERRETERA DE COMERCIO S.A.S. y el ejercicio de su objeto y sus actividades.
                    <br/><br/>
                    Particularmente, se autoriza a que SOCIEDAD FERRETERA DE COMERCIO S.A.S., realice el tratamiento de datos personales para las siguientes actividades: 1) Levantamiento y verificación de lo contenido en la base
                    de datos del cliente para fines de cumplimiento normativo en materia SAGRILAFT, PTEE, y cualquier otra norma que la modifique o adicione así como cualquier otra emanada de una entidad supervisora y/o gubernamental del
                    territorio Colombiano 2) Reportes o informes exigidos de acuerdo a la normativa Colombiana para efectos contables, tributarios, de seguridad y salud en el trabajo 3) Publicidad y campañas de fidelización de los clientes
                    de SOCIEDAD FERRETERA DE COMERCIO S.A.S.. 4) Facturación y otra documentación contable exigida por las normas internacionales que exija la información de terceros. 5) estudios y evaluaciones estadísticos de
                    conocimiento transaccional para fines de mercadeo, de segmentación o evaluación de riesgos.
                    </p>
                </div>
            </Container>
            <Footer/>
        </>
    )
}
export {TerminosCondiciones};