import React, { useEffect, useState } from 'react';
import { Footer } from '../components/Footer';
import {Container, Row, Col, Form, Button, Modal, InputGroup, Figure} from 'react-bootstrap';
import axios from 'axios';
import { Link } from 'react-router-dom';
import * as Icon from 'react-bootstrap-icons';
import Cookie from 'universal-cookie';
import {useNavigate} from 'react-router-dom';

function FormularioActualizarUsuario () {

    const [validated, setValidated] = useState(false);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [mensajeValidacionPassword, setMensajeValidacionPassword] = useState();
    const [mensajeValidacionPasswordNueva, setMensajeValidacionPasswordNueva] = useState();
    const [mensajeValidacionPasswordNuevaVerificacion, setMensajeValidacionPasswordNuevaVerificacion] = useState();
    const [mensaje, setMensaje] = useState();
    const [mensajeValidacionEmail, setMensajeValidacionEmail] = useState();
    const [mensajeValidacionTelefono, setMensajeValidacionTelefono] = useState();

    let navigate = useNavigate();
    let cookies = new Cookie();
    let imagenLogo = process.env.REACT_APP_SERVER_IMAGE+'estaticas/logo.webp';

    const [objFormulario, setObjFormulario] = useState({
        id_cliente:"",
        numero_identificacion:"",
        nombre:"",
        apellido_1:"",
        apellido_2:"",
        direccion:"",
        departamento:"",
        ciudad:"",
        barrio:"",
        telefono:"",
        email:"",
        password_cliente:"",
        codigo_vendedor:"",
        condicion_de_pago:"",
        tipo_de_cliente:"",
        grupo_descuento:"",
        lista_de_precios:"",
        difusion_publicitaria_cliente:"",
        tipo_cliente_pagina:"",
        tipo_descuento_cliente:"",
        estado_cliente:"",
        fecha_creacion:"",
        sucursal:"", 
        password_cliente_nueva:"",
        password_cliente_nueva_verificacion:"",
    });
    
    const handleSubmit = async (event) => {
        event.preventDefault();
        validarPasswordNueva();
        const form = event.currentTarget;
        if (!form.checkValidity()) {
            event.preventDefault();
            event.stopPropagation();  
        }else{
            const url = process.env.REACT_APP_SERVER + '/api/v1/customers/edit';
            const response = await axios.post(url, objFormulario);
            const data = response.data;
            setMensaje(data["message"]);
            setShow(true);
            if(data["result"]){
                cookies.set('user', data.usuario[0], { path: '/' });
                setTimeout(() => {
                    navigate('/catalogo');
                },2000);
            }
        }

        setValidated(true);
    };

    const consultarInfoUsuario = async () => {
        let user = cookies.get('user');
        const url = process.env.REACT_APP_SERVER + '/api/v1/customers/consultarUsuario';
        const response = await axios.post(url, user);
        const data = response.data;
        if(data.result !== false){
            setObjFormulario(data.result[0]);
        }else{
            document.getElementById("btnIngresar").disabled=true;
        }
    }

    useEffect(() => {
        consultarInfoUsuario();
        // eslint-disable-next-line
    },[]);

    const changeFormulario = (e) => {
        let {name, value} = e.target;
        setObjFormulario({
            ...objFormulario,
            [name]:value
        });
    }

    const changeDireccion = (e) => {
        const {value} = e.target;
        if(value === ""){
            changeFormulario(e);
        }else{
            let regex = new RegExp("^[a-zA-Z0-9 ]+$");
            if (regex.test(value)) {
                changeFormulario(e);
            }
        } 
    }

    function validacionCaracteres(texto, caracteres){
        for(let i=0; i<texto.length; i++){
           if (caracteres.indexOf(texto.charAt(i),0) !== -1){
              return true;
           }
        }
        return false;
    }

    const blurPassword = (e) => {
        changeFormulario(e);
        let letras_minusculas="abcdefghyjklmnñopqrstuvwxyz";
        let letras_mayusculas="ABCDEFGHYJKLMNÑOPQRSTUVWXYZ";
        let numeros="0123456789";
        let simbolos="!/%#$*";

        const {name, value} = e.target;
        let validacion = false;
        let mensaje = "";

        if(value !== ""){
            //valida la longitud
            if(value.length < 12){
                mensaje="Debe de contener minímo 12 carácteres";
            }else{
                //valida letra minusculas
                if (validacionCaracteres(value, letras_minusculas)) {
                    //valida letra mayúscula
                    if (validacionCaracteres(value, letras_mayusculas)) {
                        //valida números
                        if (validacionCaracteres(value, numeros)) {
                            //valida simbolo 
                            if (validacionCaracteres(value, simbolos)) {
                                validacion = true; 
                            }else{
                                mensaje="Debe de contener al menos 1 de los siguientes carácteres especiales (!, /, %, #, $, *)";
                            }
                        }else{
                            mensaje="Debe de contener al menos 1 número";
                        }
                    }else{
                        mensaje="Debe de contener al menos 1 letra mayúscula";
                    }
                }else{
                    mensaje="Debe de contener al menos 1 letra minúscula";
                }
            }
        }
        if(!validacion) {
            e.target.classList.remove('is-valid');
            e.target.classList.add('is-invalid');
            e.target.setCustomValidity('invalid');
        }else{
            e.target.classList.remove("is-invalid");
            e.target.classList.add('is-valid');
            e.target.setCustomValidity('');
        }
        switch(name){
            case 'password_cliente':
                setMensajeValidacionPassword(mensaje);
            break;

            case 'password_cliente_nueva':
                setMensajeValidacionPasswordNueva(mensaje);
            break;

            case 'password_cliente_nueva_verificacion':
                setMensajeValidacionPasswordNuevaVerificacion(mensaje);
            break;

            default:
            break;
        }
         
    }

    const mostrarPassword = (id) => {
		var campo = document.getElementById("password_cliente"+id);
        if(campo !== null){
            if(campo.type === "password"){
                campo.type = "text";
                document.getElementById("iconoOcultar"+id).style="display:none";
                document.getElementById("iconoMostrar"+id).style="display:block";
            }else{
                campo.type = "password";
                document.getElementById("iconoOcultar"+id).style="display:block";
                document.getElementById("iconoMostrar"+id).style="display:none";
            }
        }
	}

    const validarPasswordNueva = () => {
        if(objFormulario.password_cliente_nueva !== objFormulario.password_cliente_nueva_verificacion){
            document.getElementById("password_cliente_nueva").classList.remove("is-valid"); 
            document.getElementById("password_cliente_nueva").classList.add("is-invalid");
            document.getElementById("password_cliente_nueva").setCustomValidity("invalid");
            setMensajeValidacionPasswordNueva("La nueva contraseña no coincide con la verificación"); 

            document.getElementById("password_cliente_nueva_verificacion").classList.remove("is-valid"); 
            document.getElementById("password_cliente_nueva_verificacion").classList.add("is-invalid");
            document.getElementById("password_cliente_nueva_verificacion").setCustomValidity("invalid");
            setMensajeValidacionPasswordNuevaVerificacion("La nueva contraseña no coincide con la verificación"); 
        }else{
            document.getElementById("password_cliente_nueva").classList.remove("is-invalid"); 
            document.getElementById("password_cliente_nueva").classList.add("is-valid");
            document.getElementById("password_cliente_nueva").setCustomValidity("");
            setMensajeValidacionPasswordNueva(""); 

            document.getElementById("password_cliente_nueva_verificacion").classList.remove("is-invalid"); 
            document.getElementById("password_cliente_nueva_verificacion").classList.add("is-valid");
            document.getElementById("password_cliente_nueva_verificacion").setCustomValidity("");
            setMensajeValidacionPasswordNuevaVerificacion(""); 
        }
    }

    const changeEmail = (e) => {
        let validacion = true;
        const { value } = e.target;
        if (value === "") {
            validacion = false;
        }
        if (value.indexOf('@') === -1 || value.indexOf('.') === -1) {
            validacion = false;
        }
        // Verificar que el '@' esté antes del último '.'
        if (value.lastIndexOf('@') > value.lastIndexOf('.')) {
            validacion = false;
        }
        // Verificar que hay caracteres antes del '@' y después del último '.'
        if (value.indexOf('@') === 0 || value.lastIndexOf('.') === value.length - 1) {
            validacion = false;
        }
        // Verificar que haya al menos un carácter entre el '@' y el último '.'
        if (value.lastIndexOf('.') - value.indexOf('@') <= 1) {
            validacion = false;
        }
        // Verificar que el texto después del último punto tenga más de dos caracteres
        let ultimoPuntoIndex = value.lastIndexOf('.');
        let textoDespuesPunto = value.substring(ultimoPuntoIndex + 1);
        if (textoDespuesPunto.length <= 1) {
            validacion = false;
        }
        if (!validacion) {
            document.getElementById("email").classList.remove("is-valid");
            document.getElementById("email").classList.add("is-invalid");
            document.getElementById("email").setCustomValidity("invalid");
            setMensajeValidacionEmail('Debe ingresar un correo valido');
        } else {
            document.getElementById("email").classList.remove("is-invalid");
            document.getElementById("email").classList.add("is-valid");
            document.getElementById("email").setCustomValidity("");
            setMensajeValidacionEmail('');
        }
        changeFormulario(e);
    }

    const validacionTelefono = (e) => {
        const { value } = e.target;
        let mensaje = '';
        let validacion = true;
        if (value.length !== 10) {
            validacion = false;
            mensaje = 'El campo debe contener 10 dígitos';
        }
        setMensajeValidacionTelefono(mensaje)

        if (!validacion) {
            document.getElementById("telefono").classList.remove("is-valid");
            document.getElementById("telefono").classList.add("is-invalid");
            document.getElementById("telefono").setCustomValidity("invalid");
        } else {
            document.getElementById("telefono").classList.remove("is-invalid");
            document.getElementById("telefono").classList.add("is-valid");
            document.getElementById("telefono").setCustomValidity("");
        }
        changeFormulario(e);
    }
    
    return (
        <>
        <Container fluid="md">
            <Row>
                <Col sm={12}>
                    <Link to='/'>
                        <Figure.Image
                            className='image-logo'
                            alt="171x180"
                            src={imagenLogo}
                        />
                    </Link>
                </Col>
            </Row>
            <Row style={{alignItems: 'center'}}>
                <Col sm={12}>
                    <Row className="m-3 p-3">
                        <Row>
                            <Col sm={12}>
                                <h4>Actualización de datos</h4>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={7} className="m-auto">
                                <label>Por favor ingresar los siguientes datos </label>
                                <br></br>
                                <br></br>

                                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                    <Form.Group as={Row} className="mb-3">
                                        <Form.Label column sm={4}>
                                        Número Documento de Identificación
                                        </Form.Label>
                                        <Col sm={8}>
                                            <Form.Control type="number" name="numero_identificacion" value={objFormulario.numero_identificacion} placeholder="Número Documento de Identificación" disabled/>
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} className="mb-3">
                                        <Form.Label column sm={4}>
                                        Nombres o Razón Social
                                        </Form.Label>
                                        <Col sm={8}>
                                            <Form.Control type="text" name="nombre" value={objFormulario.nombre} placeholder="Nombres" disabled/>
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} className="mb-3">
                                        <Form.Label column sm={4}>
                                        Primer Apellido
                                        </Form.Label>
                                        <Col sm={8}>
                                        <Form.Control type="text" name="apellido_1" value={objFormulario.apellido_1} placeholder="Primer Apellido" disabled/>
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} className="mb-3">
                                        <Form.Label column sm={4}>
                                        Segundo Apellido
                                        </Form.Label>
                                        <Col sm={8}>
                                            <Form.Control type="text" name="apellido_2" value={objFormulario.apellido_2} placeholder="Segundo Apellido" disabled/>
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} className="mb-3">
                                        <Form.Label column sm={4}>
                                        Departamento
                                        </Form.Label>
                                        <Col sm={8}>
                                        <Form.Control type="text" name="departamento" value={objFormulario.departamento} placeholder="Departamento" disabled/>
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} className="mb-3">
                                        <Form.Label column sm={4}>
                                        Ciudad
                                        </Form.Label>
                                        <Col sm={8}>
                                            <Form.Control type="text" name="ciudad" value={objFormulario.ciudad} placeholder="Ciudad" disabled/>
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} className="mb-3">
                                        <Form.Label column sm={4}>
                                        Barrio
                                        </Form.Label>
                                        <Col sm={8}>
                                        <Form.Control type="text" name="barrio" value={objFormulario.barrio} placeholder="Barrio" disabled/>
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} className="mb-3">
                                        <Form.Label column sm={4}>
                                        Dirección
                                        </Form.Label>
                                        <Col sm={8}>
                                        <Form.Control type="text" name="direccion" placeholder="Dirección" required value={objFormulario.direccion} onChange={changeDireccion} pattern="^[a-zA-Z0-9 ]+$" autoComplete="off"/>
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} className="mb-3">
                                        <Form.Label column sm={4}>
                                        Teléfono
                                        </Form.Label>
                                        <Col sm={8}>
                                            <Form.Control type="number" id="telefono" name="telefono" value={objFormulario.telefono} placeholder="Teléfono" required onChange={validacionTelefono} autoComplete="off"/>
                                            {mensajeValidacionTelefono !== "" && <small className="text-danger">{mensajeValidacionTelefono}</small>}
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} className="mb-3">
                                        <Form.Label column sm={4}>
                                        Correo Electrónico
                                        </Form.Label>
                                        <Col sm={8}>
                                            <Form.Control type="email" id="email" name="email" value={objFormulario.email} placeholder="Correo Electrónico" required onChange={changeEmail} autoComplete="off"/>
                                            {mensajeValidacionEmail !== "" && <small className="text-danger">{mensajeValidacionEmail}</small>}
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} className="mb-3">
                                        <Form.Label column sm={4}>
                                        Contraseña Actual
                                        </Form.Label>
                                        <Col sm={8}>
                                        <InputGroup className="mb-3">
                                            <Form.Control type="password" name="password_cliente" id="password_cliente" placeholder="Contraseña" required autoComplete="off" onChange={blurPassword}/>
                                            <Button variant="outline-warning" onClick={()=>mostrarPassword("")}>
                                                <Icon.Eye id="iconoMostrar" style={{display:"none"}}/>
                                                <Icon.EyeSlash id="iconoOcultar"/>
                                            </Button>
                                        </InputGroup>
                                        {mensajeValidacionPassword !=="" && <p className="text-danger">{mensajeValidacionPassword}</p>}
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} className="mb-3">
                                        <Form.Label column sm={4}>
                                        Contraseña Nueva
                                        </Form.Label>
                                        <Col sm={8}>
                                        <InputGroup className="mb-3">
                                            <Form.Control type="password" name="password_cliente_nueva" id="password_cliente_nueva" placeholder="Contraseña" autoComplete="off" onChange={blurPassword}/>
                                            <Button variant="outline-warning" onClick={()=>mostrarPassword("_nueva")}>
                                                <Icon.Eye id="iconoMostrar_nueva" style={{display:"none"}}/>
                                                <Icon.EyeSlash id="iconoOcultar_nueva"/>
                                            </Button>
                                        </InputGroup>
                                        {mensajeValidacionPasswordNueva !=="" && <p className="text-danger">{mensajeValidacionPasswordNueva}</p>}
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} className="mb-3">
                                        <Form.Label column sm={4}>
                                        Verificación Contraseña Nueva
                                        </Form.Label>
                                        <Col sm={8}>
                                        <InputGroup className="mb-3">
                                            <Form.Control type="password" name="password_cliente_nueva_verificacion" id="password_cliente_nueva_verificacion" placeholder="Contraseña"  autoComplete="off" onChange={blurPassword}/>
                                            <Button variant="outline-warning" onClick={()=>mostrarPassword("_nueva_verificacion")}>
                                                <Icon.Eye id="iconoMostrar_nueva_verificacion" style={{display:"none"}}/>
                                                <Icon.EyeSlash id="iconoOcultar_nueva_verificacion"/>
                                            </Button>
                                        </InputGroup>
                                        {mensajeValidacionPasswordNuevaVerificacion !=="" && <p className="text-danger">{mensajeValidacionPasswordNuevaVerificacion}</p>}
                                        </Col>
                                    </Form.Group>

                                    <div className="d-grid gap-2">
                                        <Button variant="outline-warning" type="submit" id="btnIngresar">Actualizar</Button>
                                    </div>
                                </Form>
                            </Col>
                        </Row>
                    </Row>
                </Col>
            </Row>

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{mensaje}</Modal.Title>
                </Modal.Header>
            </Modal>
        </Container>
        <Footer />
        </>
    );
}

export {FormularioActualizarUsuario};