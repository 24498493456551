import React, {useEffect, useState, useRef} from 'react';
import {Container, Row, Col, Form, Button, Figure, InputGroup, Overlay, Accordion,
     Popover, Nav, NavDropdown, Offcanvas, Card, Dropdown} from 'react-bootstrap';
import '../sources/styles/stylesHeader.css'
import * as Icon from 'react-bootstrap-icons';
import Cookies from 'universal-cookie';
import axios from 'axios';
import { Link, useLocation, useNavigate} from 'react-router-dom';

function Header (props) {

    const [validateLogin, setValidateLogin] = useState(false);
    const [show, setShow] = useState(false);
    const [target, setTarget] = useState(null);
    const [text, setText] = useState('');
    const ref = useRef(null);
    const [usuario, setUsuario] = useState([]);    
    const [permisoCotizacion, setPermisoCotizacion] = useState('block')
    const [permisoFiltroBuscador, setPermisoFiltroBuscador] = useState('none')
    const [espacioPedido, setEspacioPedido] = useState('none')
    const [suiteFractal, setSuiteFractal] = useState('none')
    const [suiteFractalCanvas, setSuiteFractalCanvas] = useState('none')
    const [tokenCorreo, setTokenCorreo ] = useState([]);
    const [token, setToken ] = useState([]);
    const [puntos, setPuntos] = useState('0');
    const [arrayRelacionGranLinea, setArrayRelacionGranLinea] = useState([]);
    const [arrayGranLinea, setArrayGranLinea] = useState([]); 
    const [arrayPrueba, setArrayPrueba] = useState([]); 
    const [searchType, setSearchType] = useState('catalogo'); 
    const [showCanvas, setShowCanvas] = useState(false);
    const handleClose = () => setShowCanvas(false);
    const handleShow = () => setShowCanvas(true);
    
    const logo =  process.env.REACT_APP_SERVER_IMAGE+'estaticas/';
    let navigate = useNavigate();
    let location = useLocation();
    let cookies = new Cookies();

    function ParseJwt (token){
        let base64Url = token.split('.')[1];
        let base64 = base64Url.replace('-', '+').replace('_', '/');
        let datosToken = {datos: JSON.parse(window.atob(base64)), token_key:token.split('.')[0]}
        
        return datosToken;
    };
      
    function distintos(arr, indice) {
        let indices = [];
        let resultado = [];
      
        for (let i = 0; i < arr.length; i++) {
          if (indices.indexOf( arr[i][indice] ) < 0) {
            resultado.push( arr[i] );
            indices.push( arr[i][indice] );
          }
        }        
        return resultado;
    }

    const validacionToken = async () => {
        let arrayDireccion = location['pathname'].split('/');
        const accessToken = cookies.get('accesToken');
        let tok= ParseJwt(accessToken)
        setTokenCorreo(tok.datos.email)
        setToken(tok.token_key);

        try {
            
            const url = process.env.REACT_APP_SERVER + '/requireAuth/validacionToken';
            const response = await axios.get(url, {headers: {
                "Authorization": `Bearer ${accessToken}`
            }});
            const data = response.data;
            cookies.set('accesToken', data.accessToken, {path: '/'});
            const user = cookies.get('user')   

            const ultLogo = process.env.REACT_APP_SERVER + '/api/v1/validarListaPrecio';
            const respLogo = await axios.get(ultLogo, {headers: {
                "Authorization": `Bearer ${accessToken}`
            }});
            
            if(respLogo.data.lista_de_precios === '014' || respLogo.data.lista_de_precios === '010' || respLogo.data.lista_de_precios === '001'){
                setPermisoCotizacion('none');
                setPermisoFiltroBuscador('block');
                if(respLogo.data.lista_de_precios === '001'){
                    if(window.innerWidth <= 445){
                        setSuiteFractal('none')
                    }else{
                        setSuiteFractal('block')
                    }
                    setSuiteFractalCanvas('block')
                }
                if(respLogo.data.lista_de_precios === '001'){
                    setEspacioPedido('block')
                }else{
                }
            }else{
                setPermisoCotizacion('block');
                setPermisoFiltroBuscador('none');
            }
            if(location.pathname === '/'){
                document.getElementById('logoFront').src = process.env.REACT_APP_SERVER_IMAGE+'estaticas/logo.webp';
            }else{
                const imgLogo = process.env.REACT_APP_SERVER + '/api/v1/validarLogoCatalogo';
                const respImag = await axios.post(imgLogo, {lista: respLogo.data.lista_de_precios}, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    }
                });
                let imagenLogo = respImag.data[0].logo;
                
                document.getElementById('logoFront').src = logo+imagenLogo;

            }
            const urlCarrito = process.env.REACT_APP_SERVER + '/api/v1/shoppingCart/list';
            const responseCarrito = await axios.get(urlCarrito, {headers: {
                "Authorization": `Bearer ${accessToken}`
            }});

            const dataCarrito = responseCarrito.data[0];
            
            if(responseCarrito.data[0].length > 0){
                cookies.set('carrito', (dataCarrito.length));
            }else{
                cookies.set('carrito', 0);
            }

            if(user.tipo_usuario === 'temporal'){
                setValidateLogin(false);
                setPermisoFiltroBuscador('block');
            }else{
                setValidateLogin(true);
            }
            setUsuario(user);
            setPuntos('0');
            if(respLogo.data.lista_de_precios === '001'){
                const urlPuntos = process.env.REACT_APP_SERVER + '/api/v1/customers/puntos/client';
                const resultPuntos  = await axios.post(urlPuntos , respLogo.data);
                
                if(resultPuntos.data[0].puntos !== null){
                    setPuntos(resultPuntos.data[0].puntos);
                }
            }
        }catch (err) {
            if(err.response.status === 403){
                if(arrayDireccion[1] !== "" && 
                arrayDireccion[1] !== "productSingle"){
                    cookies.remove('accesToken');
                    cookies.remove('user');
                    navigate('/inicioSesion');
                }
            }  
        }
    }

    const grandesLineas = async ()=>{
        try {
            const accessToken = cookies.get('accesToken');
            const urlGranLiena = process.env.REACT_APP_SERVER + '/api/v1/items/gran_linea';
            const dataGranLinea = await axios.get(urlGranLiena, {
                headers: {
                    'Authorization':`Bearer ${accessToken}`
                }
            });
            const granLinea = dataGranLinea.data[0];
            setArrayRelacionGranLinea(granLinea)

            const urlGranLienaRelacion = process.env.REACT_APP_SERVER + '/api/v1/items/gran_linea_relacion';
            const dataGranLineaRelacion = await axios.get(urlGranLienaRelacion, {
                headers: {
                    'Authorization':`Bearer ${accessToken}`
                }
            });
            const granLineaRelacion = dataGranLineaRelacion.data[0];
            setArrayPrueba(granLineaRelacion) 
        } catch (error) {
            console.log(error);
        }         
    }

    const cerrarSesion = async () => {
        cookies.remove('accesToken');
        cookies.remove('user');
        navigate('/');
        document.getElementById('logoFront').src = process.env.REACT_APP_SERVER_IMAGE+'estaticas/logo.webp';
        window.location.reload();
    }

    const handleClick = (event) => {
        setShow(!show);
        setTarget(event.target);
    };

    const handleBusqueda = e => {
        setText(e.target.value);
    }  

    const busquedaPromo = async (e) => {
        setText('promocional');
    }
    
    const busquedaItems = async (e) => {
        handleClose();
        e.preventDefault();
        e.stopPropagation();

        const cookies = new Cookies();
        const accessToken = cookies.get('accesToken');
        cookies.remove('menu');
        cookies.remove('paginas');
        cookies.remove('textoBuscar');
        cookies.remove('rango');
        cookies.remove('filtro');
        cookies.set('rangoInicia', 0);
        const texto = text.replace(/'/g, "");
        try {
            const url = process.env.REACT_APP_SERVER + '/api/v1/items/busqueda';
            let body;
            if (props.busquedaItems === undefined) {
        
                body = { text: texto, rangoInicial: 0, rangoFinal: props.busquedaItems === undefined ? 12 : props.busquedaItems.rangoFinal };
            } else {
                body = { text: texto, rangoInicial: 0, rangoFinal: props.busquedaItems === undefined ? 12 : props.busquedaItems.rangoFinal, filtro: props.busquedaItems.filtro };
            }
        
            let countPages = 0;
            let dataBusqueda = [];
            if (searchType === 'catalogo') {
                const urlPags = process.env.REACT_APP_SERVER + '/api/v1/items/numPags/busqueda';
                let responsePags = await axios.post(urlPags, body, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    }
                });
        
                if (responsePags.data[0] < 2 && /\D/.test(texto)) {
        
                    let sugerencia = 'default';
                    let responsePagsInicial = responsePags;
        
                    if (responsePags.data[0] === 0) {
        
                        const urlPalabra = process.env.REACT_APP_SERVER + '/api/v1/items/busquedaPalabra';
                        const { data } = await axios.post(urlPalabra, { text: texto }, {
                            headers: {
                                'Authorization': `Bearer ${accessToken}`
                            }
                        });
        
                        if (data.length === 0) {
        
                            const urlPalabras = process.env.REACT_APP_SERVER + '/api/v1/items/busquedaPalabras';
                            const { data } = await axios.post(urlPalabras, texto, {
                                headers: {
                                    'Authorization': `Bearer ${accessToken}`
                                }
                            });
        
                            function obtenerTodasLasPalabras(datos) {
        
                                let todasLasPalabras = new Set();
        
                                datos.forEach((objeto) => {
                                    let palabras = objeto.palabras_clave.split(',');
                                    palabras.forEach((palabra) => {
                                        palabra = palabra.trim();
                                        todasLasPalabras.add(palabra);
                                    });
                                });
        
                                return Array.from(todasLasPalabras);
                            }
        
                            function levenshteinDistance(str1, str2) {
                                const m = str1.length;
                                const n = str2.length;
                                const dp = Array.from(Array(m + 1), () => Array(n + 1).fill(0));
        
                                for (let i = 0; i <= m; i++) {
                                    dp[i][0] = i;
                                }
        
                                for (let j = 0; j <= n; j++) {
                                    dp[0][j] = j;
                                }
        
                                for (let i = 1; i <= m; i++) {
                                    for (let j = 1; j <= n; j++) {
                                        if (str1[i - 1] === str2[j - 1]) {
                                            dp[i][j] = dp[i - 1][j - 1];
                                        } else {
                                            dp[i][j] = Math.min(
                                                dp[i - 1][j - 1] + 1,
                                                dp[i][j - 1] + 1,
                                                dp[i - 1][j] + 1
                                            );
                                        }
                                    }
                                }
        
                                return dp[m][n];
                            }
        
                            function sugerirCorreccion(busqueda, listaPalabras) {
        
                                let sugerencias = [];
                                const umbralDistancia = 1;
        
                                for (const palabra of listaPalabras) {
                                    const distancia = levenshteinDistance(busqueda, palabra);
                                    if (distancia <= umbralDistancia) {
                                        sugerencias.push(palabra);
                                    }
                                }
        
                                return sugerencias;
                            }
                            let todasLasPalabras = obtenerTodasLasPalabras(data);
                            let correcciones = sugerirCorreccion(texto, todasLasPalabras);
                            sugerencia = correcciones.join(' ');
                        } else {
                            sugerencia = data[0].sugerencia;
                        }
        
                        if (sugerencia === '') {
                            sugerencia = texto;
                        }
        
                        if (props.busquedaItems === undefined) {
        
                            body = { text: sugerencia, rangoInicial: 0, rangoFinal: props.busquedaItems === undefined ? 12 : props.busquedaItems.rangoFinal };
                        } else {
                            body = { text: sugerencia, rangoInicial: 0, rangoFinal: props.busquedaItems === undefined ? 12 : props.busquedaItems.rangoFinal, filtro: props.busquedaItems.filtro };
                        }
        
                        responsePags = await axios.post(urlPags, body, {
                            headers: {
                                'Authorization': `Bearer ${accessToken}`
                            }
                        });
        
                        if (data.length === 0) {
        
                            let resultadosBusqueda = { text: texto, resultPaginacionI: responsePagsInicial.data[0], sugerencia: sugerencia, resultPaginacionF: responsePags.data[0] };
                            const urlInsertarBusqueda = process.env.REACT_APP_SERVER + '/api/v1/items/insertarBusqueda';
        
                            await axios.post(urlInsertarBusqueda, resultadosBusqueda, {
                                headers: {
                                    'Authorization': `Bearer ${accessToken}`
                                }
                            });
                        }
                    }
                }
        
                const { data } = await axios.post(url, body, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    }
                });
                countPages = responsePags.data[0];
                dataBusqueda = data;
            } else {
                if (searchType === 'Ujueta') {
                    try {
                        const urlUjuetaBd = process.env.REACT_APP_SERVER + '/api/v1/items/ujueta/search/';
                        const { data: resultUjuetaBd } = await axios.post(urlUjuetaBd, {text: texto},{
                            headers: {
                                'Authorization': `Bearer ${accessToken}`
                            },
                        });
                        if (resultUjuetaBd.result) {
                            countPages = resultUjuetaBd.productos.length;
                            dataBusqueda = resultUjuetaBd.productos;
                        } else {
                            countPages = 0;
                            dataBusqueda = [];
                        }
                    } catch (e) {
                        countPages = 0;
                        dataBusqueda = [];
                        console.error('Error al obtener la información del producto desde Ujueta:', e);
                    }
                }
            }
            let arrayAux = [];
            if (countPages > 5) {
                arrayAux = [0, 1, 2, 3, 4]
            } else {
                for (let index = 0; index < countPages; index++) {
                    arrayAux.push(index)
                }
            }
            let page = location.pathname.split('/')[1]
            if (page !== 'catalogo') {
                navigate('/' + searchType, { state: { data: dataBusqueda, page: '/' + page, numPags: arrayAux, limitPags: countPages, text: texto, searchType: searchType } })
                cookies.set('textoBuscar', body.text, { path: '/' });
            } else {
                if (searchType !== 'catalogo') {
                    navigate('/' + searchType, { state: { data: dataBusqueda, page: '/' + page, numPags: arrayAux, limitPags: countPages, text: texto, searchType: searchType } })
                    cookies.set('textoBuscar', body.text, { path: '/' });
                } else {
                    cookies.set('textoBuscar', body.text, { path: '/' });
                    props.busquedaItems.setTitleBusqueda(texto);
                    props.busquedaItems.setText(texto);
                    props.busquedaItems.setItems(dataBusqueda);
                    props.busquedaItems.setNumPags(arrayAux);
                    props.busquedaItems.setLimitPage(countPages);
        
                    if (cookies.get('paginas') !== undefined) {
                        props.busquedaItems.setValidacionPaginacion(cookies.get('paginas'))
                    } else {
                        props.busquedaItems.setValidacionPaginacion(1)
                    }
                    props.busquedaItems.setTextValidacion(true)
                    props.busquedaItems.setVisualizacionProductos('none')
                }
            }
        } catch( err) {
            console.log(`Ocurrió error en la consulta de busqueda items en hooks: ${err}`)
        }
    }

    const capitalizarPrimeraLetra = (str) =>{
      return str.charAt(0).toUpperCase() + str.slice(1);
    }

    const queryLineas = async (id) => {
        let objSublinea = [];        
        let objFinal = [];
        let objGranlinea = []
        
        for (let i = 0; i < arrayPrueba.length; i++) {
            if(arrayPrueba[i].cod_granlinea=== id){
                objGranlinea.push(arrayPrueba[i]);
            }            
        }

        let granLinea = distintos(objGranlinea, "cod_linea")
       
        for (let l = 0; l < granLinea.length; l++) {
            let objSublineaTemp = [];    
            objSublinea = [];  
            for (let sublinea = 0; sublinea < objGranlinea.length; sublinea++) {
                if(granLinea[l].cod_linea === objGranlinea[sublinea].cod_linea){
                    objSublineaTemp.push({
                        cod_sublinea: objGranlinea[sublinea].cod_sublinea,
                        descripcion_sublinea: objGranlinea[sublinea].descripcion_sublinea
                    })                    
                }                
            }

            objSublineaTemp = distintos(objSublineaTemp, "cod_sublinea")
            
            for (let s = 0; s < 7; s++) {
                
                if(objSublineaTemp[s] === undefined){
                    objSublinea.push({
                        cod_sublinea: '',
                        descripcion_sublinea: ''
                    });                        
                }else{                            
                    objSublinea.push({
                        cod_sublinea: objSublineaTemp[s].cod_sublinea,
                        descripcion_sublinea: capitalizarPrimeraLetra(objSublineaTemp[s].descripcion_sublinea)
                    });
                    if(s === 6){
                        if(objSublineaTemp[s] !== undefined){
                            objSublinea.push({
                                cod_sublinea: '---',
                                descripcion_sublinea: 'VER MAS ->'        
                            });
                        }
                    }                        
                }
            }
            objFinal[l]={
                linea:{
                    cod_linea: granLinea[l].cod_linea,
                    nombre_linea: granLinea[l].descripcion_linea
                },
                objSublinea
            }
        }
        cookies.remove('paginas');        
        cookies.remove('textoBuscar');
        setArrayGranLinea(objFinal)
    }

    const handleDireccion = async(metodo, id, nombre, linea) =>{
        cookies.remove('menu');
        let menu = '';

        if(id !== '---'){            
            menu = `${metodo}_${id}_${nombre}`;
        }else{
            menu = `masSublineas_${linea.cod_linea}_${linea.nombre_linea}`;
        }        
        cookies.set('menu', menu, {path: '/'});
        if(location.pathname === "/catalogo"){
            window.location.reload();
        }else{
            navigate('/catalogo');
        }
    }

    const filtroCanvas = () =>{
        return(
            <>
            <Accordion.Header><Icon.Funnel/>  CATEGORIAS</Accordion.Header>
                <Accordion.Body>
                    <Accordion>
                    <Form onSubmit={(e) => busquedaItems(e)}>
                        <InputGroup className='contenidoTexto'>
                            <Button onClick={busquedaPromo} type="submit" variant="default">
                                <strong>PROMOCIONES</strong>
                                <Form.Control hidden defaultValue={text} />
                            </Button>
                        </InputGroup>
                    </Form>
                        {
                            arrayRelacionGranLinea.map((fila,id)=>
                                <Accordion.Item eventKey={id} key={id}>
                                    <Accordion.Header onClick={() => queryLineas(fila.cod_gran_linea)} >{fila.nombre}</Accordion.Header>
                                        <Accordion.Body>
                                            <Accordion>
                                                {
                                                    arrayGranLinea.map((filaLinea, idLinea)=>
                                                        <Accordion.Item eventKey={idLinea} key={idLinea}>
                                                            <div className='contenedorCanvas'>
                                                                <Accordion.Header className='lineaCanvas' onClick={() => handleDireccion('linea',filaLinea.linea.cod_linea, filaLinea.linea.nombre_linea, '')}>{filaLinea.linea.nombre_linea}</Accordion.Header>
                                                                    <Accordion.Header id={`abrirCanvas${idLinea}`}  className='canvaAbrir'>
                                                                    <label className='flechaCanva'> <Icon.CaretRight/></label>
                                                                </Accordion.Header>
                                                            </div>
                                                            <Accordion.Body>
                                                                <Accordion>
                                                                    {
                                                                    filaLinea.objSublinea.map((fila1, id1) =>
                                                                        <Accordion.Item eventKey={id1} key={id1}>
                                                                            <ul onClick={() => handleDireccion('sublinea',fila1.cod_sublinea, fila1.descripcion_sublinea, filaLinea.linea)} >{fila1.descripcion_sublinea}</ul>
                                                                        </Accordion.Item>
                                                                        )
                                                                    }
                                                                </Accordion>
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                    )
                                                }
                                            </Accordion>
                                        </Accordion.Body>
                                </Accordion.Item>
                            )
                        }                                                            
                    </Accordion>
                </Accordion.Body>
            </>
        )
    } 

    const RemoverCooki=(direccion)=>{
        cookies.remove('menu');
        cookies.remove('paginas');
        cookies.remove('textoBuscar');
        cookies.remove('rango');
        cookies.set('rangoInicia',0);
        cookies.remove('filtro');
        
        window.location.href = direccion;
        navigate(direccion)
    }

    useEffect(() =>{
        let page = location.pathname.split('/')[1]
        page === 'Ujueta' && setSearchType(page);
        validacionToken();
        grandesLineas(); 
        // eslint-disable-next-line
    }, []);

    return(
        <>
        <Container fluid className='fixed-top bg-white pb-2 border-bottom'>
            <Container className="mb-2 contendorHeader" style={{marginTop: '10px'}} >
                <Row >
                    <Col className='colFiltro'>
                        <div className='divOffCanva'>
                            <Button  onClick={handleShow}  >
                                <Card.Img  className='filtroMenu' alt="75x75" src={process.env.REACT_APP_SERVER_IMAGE + 'estaticas/Menu3.png'}/>
                            </Button>
                            <Offcanvas show={showCanvas} onHide={handleClose}>
                                <Offcanvas.Header closeButton />
                                <Offcanvas.Body>
                                    <h5>MENÚ DE OPCIONES</h5>
                                    <Accordion>
                                        <Accordion.Item eventKey="10" >
                                            <Accordion.Header>
                                            <Form className={'formuarioBuscador'} onSubmit={(e) => busquedaItems(e)}>
                                                <InputGroup className='contenidoTexto'>
                                                    <Form.Control placeholder="Busca tu producto" className="rounded-left textBuscador" value={text} onChange={handleBusqueda} />
                                                    <Button  aria-label="Boton de busqueda" type="submit" variant="outline-warning" id="button-addon2" className='buscar'>
                                                        <Icon.Search className='ico_buscar'/>
                                                    </Button>
                                                </InputGroup>
                                            </Form>
                                            </Accordion.Header>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="0" ><Accordion.Header><Link to='/'><Icon.House/>  HOME</Link></Accordion.Header></Accordion.Item>
                                        <Accordion.Item eventKey="1" >
                                            {
                                                validateLogin === true
                                                ? 
                                                <>
                                                <Accordion.Item eventKey="4" >
                                                <Accordion.Header><Icon.Gear/>  CONFIGURACIÓN</Accordion.Header>
                                                    <Accordion.Body>
                                                        <ul style={{border: '2px solid black', textAlign:'center'}}>{usuario.nombre+" "+usuario.apellido_1+" "+usuario.apellido_2}</ul>
                                                        <ul> <Link onClick={() => handleClose()}  to='/actualizarDatosUsuario'><Icon.Person/>  Actualizar Datos</Link></ul>
                                                        <ul><Link onClick={() => handleClose()}  to='/estadoPedidos'><Icon.BoxSeam/>  Estado Pedidos</Link></ul>
                                                        <ul style={{display: espacioPedido}}><Link onClick={() => handleClose()}  to='/estadoCartera'><Icon.Wallet2/>  Estado Cartera</Link></ul>
                                                        <ul style={{display: espacioPedido}}><Link onClick={() => handleClose()}  to='/pedidoCliente'><Icon.CardList/>  Pedidos Clientes</Link></ul>
                                                        <ul style={{display: espacioPedido}}> <Link onClick={() => handleClose()}  to='/informes'><Icon.JournalBookmarkFill/>  Informes</Link></ul>
                                                        <ul style={{display: permisoCotizacion}}> <Link onClick={() => handleClose()}  to='/cotizacion'><Icon.ListTask/>  Cotizaciones</Link></ul>
                                                        <ul></ul>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="2" >
                                                <Accordion.Header><Link onClick={() => handleClose()} to='/catalogo'><Icon.FileText/>  CATALOGO</Link></Accordion.Header>
                                                    </Accordion.Item>                                                    
                                                <Accordion.Item eventKey="6">
                                                {filtroCanvas()}
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="3" >
                                                <Accordion.Header><Link onClick={() => handleClose()}  to='/carrito'><Icon.Cart/>  CARRITO<span  className="badge badge-dark">{cookies.get('carrito')}</span></Link></Accordion.Header>
                                                    </Accordion.Item>
                                                
                                                <Accordion.Item eventKey="5" >
                                                    <Accordion.Header style={{display: suiteFractalCanvas}} > 
                                                    <form target={'_new'} method={'post'} action={'http://bi.soferco.tech/sugerido_fractal/validarLogin.php'}>
                                                                <input type="hidden" name="email" value={tokenCorreo} />
                                                                <input type="hidden" name="token" value={token} />
                                                                <button  type="submit"  className='btnCanvas'><Icon.Clipboard2Data/> SUITEFRACTAL</button>
                                                        </form>
                                                    </Accordion.Header>
                                                    </Accordion.Item>
                                                    <Accordion.Item eventKey="7">
                                                    <Accordion.Header>
                                                        <button onClick={() => cerrarSesion()}  className='btnCanvas'>
                                                            <Icon.BoxArrowRight/>  SALIR
                                                        </button>
                                                    </Accordion.Header>
                                                    </Accordion.Item>

                                                    <Accordion.Item eventKey="8">
                                                        <Accordion.Header style={{display: suiteFractalCanvas}}>
                                                                <Icon.Coin/>   PUNTOS<span  className="badge badge-dark">{puntos}</span>
                                                        </Accordion.Header>
                                                    </Accordion.Item>
                                                </> 
                                                :
                                                <>
                                                    <Accordion.Item eventKey="2" ><Accordion.Header><Link onClick={() => handleClose()} to='/inicioSesion'><Icon.BoxArrowInRight/>  INICIO DE SESIÓN</Link></Accordion.Header></Accordion.Item>
                                                    <Accordion.Item eventKey="3" ><Accordion.Header><Link onClick={() => handleClose()} to='/catalogo'><Icon.FileText/>  CATALOGO</Link></Accordion.Header></Accordion.Item>
                                                    <Accordion.Item eventKey="5">{filtroCanvas()}</Accordion.Item>                                              
                                                    <Accordion.Item eventKey="4" ><Accordion.Header><Link onClick={() => handleClose()} to='/carrito'><Icon.Cart/>  CARRITO<span  className="badge badge-dark">{cookies.get('carrito')}</span></Link></Accordion.Header></Accordion.Item>
                                                </>
                                            }
                                        </Accordion.Item>
                                    </Accordion>
                                </Offcanvas.Body>
                            </Offcanvas>
                        </div>
                    </Col> 
                    <Col xs={6} sm={3} md={3} className='colLogo' style={{ textAlign: 'center'}} >
                    
                        <Link to='/'  width={'50%'} height={'20%'} onClick={() =>RemoverCooki("/")} >
                            <Figure.Image
                                id='logoFront'
                                className='image-logo'
                                alt="171x180"
                                src={process.env.REACT_APP_SERVER_IMAGE+'estaticas/logo.webp'}
                                width={'170'} height={'180'}
                            />
                        </Link>
                    </Col>
                    <Col xs={8} sm={4} md={4} className='colBuscador'>
                        <Row>
                            <Col xs={8} sm={12} md={8} className='ps-0 pe-0'>
                                <Form onSubmit={(e) => busquedaItems(e)}>
                                    <InputGroup className='contenidoTexto'>
                                        <Form.Control placeholder="Busca tu producto" className="rounded-left textBuscador" value={text} onChange={handleBusqueda} />
                                        <Button aria-label="Boton de busqueda" type="submit" variant="outline-warning" id="button-addon2" className='buscar'>
                                            <Icon.Search className='ico_buscar'/>
                                        </Button>
                                    </InputGroup>
                                </Form>
                            </Col>
                            <Col xs={4} sm={12} md={4} className='ps-0' style={{display: permisoFiltroBuscador}}>
                                <Dropdown className='contenidoTexto textBuscador' >
                                    <Dropdown.Toggle  className='dropdown-toggle pe-1 ps-1' variant="outline-secondary">
                                        <small>{searchType === 'catalogo' ? 'Ferremaster' : searchType}</small>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className='dropdown-menu-buscador'>
                                        <Dropdown.ItemText>Elige la tienda donde buscar:</Dropdown.ItemText>
                                        <Dropdown.Divider />
                                        <Dropdown.Item onClick={(e) => setSearchType('catalogo')}>
                                            <small>Ferremaster</small>
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={(e) => setSearchType("Ujueta")}>
                                            <small>Ujueta</small>
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>
                        </Row>
                    </Col>
                    {
                        validateLogin === true
                        ? 
                        <>
                            <Col xs={1} sm={1} md={1} className="colBtnInfoLogin">
                                <Link to='/catalogo' className="btn btn-light btnInfoLogin" title="Catalogo de compra" onClick={() =>RemoverCooki("/catalogo")}>
                                        <Icon.FileText/>  <p className='namePanel'>Catalogo</p>    
                                </Link>
                            </Col>
                            
                            <Col xs={1} sm={1} md={1} className="colBtnInfoLogin iniciarSesion">
                                <Link to='/carrito' className="btn btn-light btnInfoLogin" title="Carrito de compra">
                                    <div className='numberCar' id='numberCar' title="Valor Carrito"><span  className="badge badge-dark">{cookies.get('carrito')}</span></div>
                                    <Icon.Cart/><p className='namePanel'>Carrito</p>    
                                </Link>
                            </Col>
                            <Col xs={1} sm={1} md={1} className="colBtnInfoLogin">
                                <div ref={ref}>
                                    <Button variant="light" className="btn btnInfoLogin" style={{padding:'0.375rem 0px'}} onClick={handleClick}  title="Configuraciones">
                                        <Icon.Gear/> <p className='namePanel'>Ajustes</p>   
                                    </Button>

                                    <Overlay
                                        show={show}
                                        target={target}
                                        placement="bottom"
                                        container={ref}
                                        containerPadding={20}
                                    >
                                        <Popover>
                                        <Popover.Header as="h3">{usuario.nombre+" "+usuario.apellido_1+" "+usuario.apellido_2}</Popover.Header>
                                        <Popover.Body>
                                            <Row>
                                                <Form.Group className='btnForm'>
                                                    <Col>
                                                        <Link to='/actualizarDatosUsuario' className="btn btn-light btnInfoLogin conf" title="Actualizar Datos">
                                                            <Icon.Person/> Actualizar Datos
                                                        </Link>
                                                    </Col>    
                                                </Form.Group>
                                            </Row>
                                            <Row>
                                                
                                                <Form.Group className='btnForm'>
                                                    <Col>
                                                
                                                        <Link to='/estadoPedidos' className="btn btn-light btnInfoLogin conf" title="Estado Pedidos">
                                                            <Icon.BoxSeam/> Estado Pedidos
                                                        </Link>
                                                    </Col>  
                                                </Form.Group>  
                                            </Row>
                                            <Row style={{display: espacioPedido}}>
                                                <Form.Group className='btnForm'>                                                    
                                                    <Col>
                                                        <Link to='/estadoCartera' className="btn btn-light btnInfoLogin conf" title="Estado Cartera">
                                                            <Icon.Wallet2/> Estado Cartera
                                                        </Link>
                                                    </Col>    
                                                </Form.Group>
                                            </Row>
                                            
                                            <Row style={{display: espacioPedido}}>
                                                <Form.Group className='btnForm'>
                                                    <Col>
                                                        <Link to='/pedidoCliente' className="btn btn-light btnInfoLogin conf" title="Pedidos Clientes">
                                                            <Icon.CardList/> Pedidos Clientes
                                                        </Link>
                                                    </Col>    
                                                </Form.Group>
                                            </Row>
                                            <Row style={{display: espacioPedido}}>
                                                <Form.Group className='btnForm'>
                                                    <Col>
                                                        <Link to='/informes' className="btn btn-light btnInfoLogin conf" title="Pedidos Clientes">
                                                            <Icon.JournalBookmarkFill/> Informes
                                                        </Link>
                                                    </Col>    
                                                </Form.Group>
                                            </Row>
                                            <Row style={{display: permisoCotizacion}}>
                                                <Form.Group className='btnForm'>
                                                    <Col>
                                                        <Link to='/cotizacion' className="btn btn-light btnInfoLogin conf" title="Cotizaciones">
                                                            <Icon.ListTask/> Cotizaciones
                                                        </Link>
                                                    </Col>    
                                                </Form.Group>
                                            </Row>                                                                                    
                                            
                                        </Popover.Body>
                                        </Popover>
                                    </Overlay>
                                </div>
                            </Col>
                            <Col xs={1} sm={1} md={1} className="colBtnInfoLogin suiteFractal" style={{display: suiteFractal}} >
                                <form target={'_new'} method={'post'} className="btn btn-light btnInfoLogin btnForm" action={'http://bi.soferco.tech/sugerido_fractal/validarLogin.php'}>
                                        <input type="hidden" name="email" value={tokenCorreo} />
                                        <input type="hidden" name="token" value={token} />
                                        <Icon.Clipboard2Data/>
                                        <input  className='btnSuiteFractal'  title="SuiteFractal" type="submit" value="Suite" />
                                </form>
                            </Col>
                            <Col xs={1} sm={1} md={1} className="colBtnInfoLogin suiteFractal" style={{display: suiteFractal}}>
                                <Button variant="light" className="btn btnInfoLogin"  title="Puntos">
                                    <div className='numberCar' id='numberCar' title="Valor Puntos"><span  className="badge badge-dark">{puntos}</span></div>
                                    <Icon.Coin/> <p className='namePanel'>Puntos</p>
                                </Button>
                            </Col>
                            <Col xs={1} sm={1} md={1} className="colBtnInfoLogin iniciarSesion">
                                <Button variant="light" className="btn btnInfoLogin" onClick={() => cerrarSesion()}  title="Cerrar sesión">
                                    <Icon.BoxArrowRight/> <p className='namePanel'>Salir</p>
                                </Button>
                            </Col>
                        </>
                        :
                        <>
                        <Col xs={1} sm={1} md={1} className="colBtnInfoLogin iniciarSesion">
                            <Link to='/carrito' className="btn btn-light btnInfoLogin" title="Carrito de compra">
                            <div className='numberCar' id='numberCarTemporal' title="Valor Carrito"><span  className="badge badge-dark">{cookies.get('carrito')}</span></div>
                                    <Icon.Cart/> <p className='namePanel'>Carrito</p>       
 
                            </Link>
                        </Col>
                        <Col xs={1} sm={1} md={1} className="colBtnInfoLogin iniciarSesion">

                            <Link to='/inicioSesion' className="btn btn-light btnInfoLogin mr-3" title="Iniciar Sesión">
                                <Icon.BoxArrowInRight className="icon_sesion"/> <p className='namePanel'>Inicio Sesión</p>   
                            </Link>
                        </Col>
                        <Col xs={1} sm={1} md={1} className="colBtnInfoLogin">
                                <Link to='/catalogo' className="btn btn-light btnInfoLogin" title="Catalogo de compra" onClick={() =>RemoverCooki("/catalogo")}>
                                        <Icon.FileText/>  <p className='namePanel'>Catalogo</p>       
                                </Link>
                        </Col>
                        
                        </>
                    }
                </Row>
            </Container>
                
            <div className='divNac'>
                <Nav fill defaultActiveKey="/" >
                <Form onSubmit={(e) => busquedaItems(e)}>
                    <InputGroup className='contenidoTexto'>
                        <Button onClick={busquedaPromo} type="submit" variant="warning"   >
                            <strong>PROMOCIONES</strong>
                            <Form.Control hidden defaultValue={text} />
                        </Button>
                    </InputGroup>
                </Form>
                    {
                        arrayRelacionGranLinea.map((fila,id)=>
                            <NavDropdown key={id} title={fila.nombre} onClick={() => queryLineas(fila.cod_gran_linea)} className={'granLinea'}> 
                                <Row lg={4} sm={2} className={'filas'}>
                                {
                                    arrayGranLinea.map((filaLinea,idLinea)=>
                                        <Col className='columna'>                            
                                            <Nav.Item key={idLinea}>
                                                <label href='' className='lineas' onClick={() => handleDireccion('linea',filaLinea.linea.cod_linea, filaLinea.linea.nombre_linea, '')}><strong>{filaLinea.linea.nombre_linea}</strong></label>
                                                {
                                                    filaLinea.objSublinea.map((fila1, id1) =>
                                                    <Nav.Item  key={id1} onClick={() => handleDireccion('sublinea',fila1.cod_sublinea, fila1.descripcion_sublinea, filaLinea.linea)} >
                                                        {
                                                            fila1.descripcion_sublinea === 'VER MAS ->'
                                                            ?
                                                            <label className='sublineas'><b className="text-warning">{fila1.descripcion_sublinea}</b></label>
                                                            :
                                                            <label className='sublineas'>{fila1.descripcion_sublinea}</label>
                                                        }
                                                    </Nav.Item>
                                                    )
                                                }
                                            </Nav.Item>
                                        </Col>
                                    )
                                }
                                </Row>     
                            </NavDropdown>
                        )
                    }
                    <Nav.Link style={{display: permisoFiltroBuscador}} className={'granLinea'} onClick={() =>RemoverCooki("/Ujueta")}>TIENDA UJUETA</Nav.Link>
                </Nav>
            </div> 
        </Container>
        <br/><br/><br/><br/><br/><br/>
        </>
    );
}

export {Header};


