import { Container } from 'react-bootstrap';
import { Footer } from '../components/Footer';
import { Header } from '../components/Header';

import '../sources/styles/stylesTexto.css';

function PreguntasFrecuentes() {
    return (
        <>
            <Header />
            <Container fluid="md">
                <h1>PREGUNTAS FRECUENTES SOBRE PAGOS ELECTRÓNICOS</h1>
                <br></br>
                <b>¿Qué es GOU Pagos?</b>
                <div className='dateInforme'>
                    <p>GUO PAGOS es la plataforma de pagos electrónicos que usa SOCIEDAD FERRETERA DE COMERCIO S.A.S para procesar en línea las transacciones generadas en la tienda virtual con las formas de pago habilitadas para tal fin.
                    </p>
                </div>
                <b>¿Cómo puedo pagar?</b>
                <div className='dateInforme'>
                    <p>En la tienda virtual de SOCIEDAD FERRETERA DE COMERCIO S.A.S usted podrá realizar su pago con los medios habilitados para tal fin. Usted, de acuerdo con las opciones de pago escogidas por el comercio, podrá pagar a través de tarjetas de crédito Visa, y MasterCard y Cuentas debito ahorro y corriente PSE.
                    </p>
                </div>
                <b>¿Es seguro ingresar mis datos bancarios en este sitio web?</b>
                <div className='dateInforme'>
                    <p>Para proteger tus datos SOCIEDAD FERRETERA DE COMERCIO S.A.S delega en GOU pagos la captura de la información sensible. Nuestra plataforma de pagos cumple con los más altos estándares exigidos por la norma internacional PCI DSS de seguridad en transacciones con tarjeta de crédito. Además, tiene certificado de seguridad SSL expedido por GeoTrust una compañía Verisign, el cual garantiza comunicaciones seguras mediante la encriptación de todos los datos hacia y desde el sitio; de esta manera te podrás sentir seguro a la hora de ingresar la información de su tarjeta. Durante el proceso de pago, en el navegador se muestra el nombre de la organización autenticada, la autoridad que lo certifica y la barra de dirección cambia a color verde. Estas características son visibles de inmediato y dan garantía y confianza para completar la transacción en GOU Pagos. GOU Pagos también cuenta con el monitoreo constante de McAfee Secure y la firma de mensajes electrónicos con Certicámara.
                    </p>
                </div>
                <b>¿Puedo realizar el pago cualquier día y a cualquier hora?</b>
                <div className='dateInforme'>
                    <p>Sí, en SOCIEDAD FERRETERA DE COMERCIO S.A.S podrás realizar tus compras en línea los 7 días de la semana, las 24 horas del día a sólo un clic de distancia.
                    </p>
                </div>
                <b>¿Puedo cambiar la forma de pago?</b>
                <div className='dateInforme'>
                    <p>Si aún no has finalizado tu pago, podrás volver al paso inicial y elegir la forma de pago que prefieras. Una vez finalizada la compra no es posible cambiar la forma de pago.
                    </p>
                </div>
                <b>¿Pagar electrónicamente tiene algún valor para mí como comprador?</b>
                <div className='dateInforme'>
                    <p>No, los pagos electrónicos realizados a través de GOU Pagos no generan costos adicionales para el comprador.
                    </p>
                </div>
                <b>¿Qué debo hacer si mi transacción no concluyó?</b>
                <div className='dateInforme'>
                    <p>En primera instancia, revisar si llegó un email de confirmación de la transacción a la cuenta de correo electrónico inscrita en el momento de realizar el pago, en caso de no haberlo recibido, deberás contactar al área de ventas digital de SOCIEDAD FERRETERA DE COMERCIO S.A.S para confirmar el estado de la transacción.
                    </p>
                </div>
                <b>¿Qué debo hacer si no recibí el comprobante de pago?</b>
                <div className='dateInforme'>
                    <p>Por cada transacción aprobada a través de GOU Pagos, recibirás un comprobante del pago con la referencia de compra en la dirección de correo electrónico que indicaste al momento de pagar. Si no lo recibes, podrás contactar al área de ventas digital de SOCIEDAD FERRETERA DE COMERCIO S.A.S, para solicitar el reenvío del comprobante a la misma dirección de correo electrónico registrada al momento de pagar.
                    </p>
                </div>
            </Container>
            <Footer />
        </>
    )
}
export { PreguntasFrecuentes };